import React from 'react';
import LockScreen from '../../../components/lockScreen/LockScreen';

class AILensLockedPage extends React.Component {

  render() {
    return (
      <LockScreen />
    );
  }
}

export default AILensLockedPage;
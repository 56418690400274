import React from "react";
import { Row, Col } from 'react-flexbox-grid';
import PersistentDrawerLeft from '../../components/navbar/PersistentDrawerLeft';
import BottomNavbar from '../../components/navbar/BottomNavbar';

/* CSS */
import '../../assets/css/Global.css';
import './index.css';
/* Images */
import ailensImg from './images/ailens.png';
import pyxisImg from './images/pyxis.png';
import boggleImg from './images/boggle.png';
import twitterrainImg from './images/twitterrain.png';

class HomePage extends React.Component {
  render() {
    const divider = "<svg class='editorial' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 24 150 28' preserveAspectRatio='none'><defs><path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'/></defs>< g class = 'parallax1' ><use xlink: href='#gentle-wave' x='50' y='3' fill='#FF0081' /></g><g class='parallax2'><use xlink: href='#gentle-wave' x='50' y='0' fill='#4984FB'/></g><g class='parallax3'><use xlink: href='#gentle-wave' x='50' y='9' fill='#2E539E'/></g><g class='parallax4'><use xlink: href='#gentle-wave' x='50' y='6' fill='#FAFAFA'/></g></svg >";

    return (
      <PersistentDrawerLeft isMobile={true} hasMenu={false} isDark={true}>
        <div>
          <Row className='hero'>

            <Col xs={1} sm={1} md={1} lg={2}></Col>
            <Col xs={10} sm={10} md={10} lg={8}>
              <div className='name pink-txt'>Tiffany Jaya</div>
              <div className='headline desktop-view'>
                Developer turned product designer<br />
                with 7+ years building impactful products and teams
            </div>
              <div className='headline mobile-view'>
                Developer turned product designer
                with 7+ years building impactful products and teams
            </div>
              <div dangerouslySetInnerHTML={{ __html: divider }} />
            </Col>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
          </Row>
          <Row>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
            <Col xs={10} sm={10} md={10} lg={8}>
              <div className='title section-separator'>Selected Works</div>

            </Col>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
          </Row>
          <Row>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
            <Col xs={10} sm={10} md={10} lg={8} className='center-div'>
              {/*
              <a href='projects/ai-lens' className='desktop-view'>
                <div className='cs-box shadow'>
                  <div className='cs-content'>
                    <div className='title'>AI Lens</div>
                    <div>
                      Automatically find high risk drivers and show why they're risky for insurance companies
                  </div>
                    <div className='line' />
                    <div>
                      <a className='gradient-button gradient-button-purple hashtag'>#Web</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#AI</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#DataVis</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#B2B</a>
                    </div>
                  </div>
                  <div className='cs-img'>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                </div>
              </a>
              <a href='projects/ai-lens' className='mobile-view no-underline'>
                <div className='cs-mobile-box shadow'>
                  <div>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                  <div className='cs-mobile-content-box'>
                    <div className='title white-txt'>AI Lens</div>
                    <div className='white-txt'>
                      Automatically find high risk drivers and show why they're risky for insurance companies
                  </div>
                    <div className='hashtag gray-txt'>
                      #Web | #AI | #DataVis | #B2B
                  </div>
                  </div>
                </div>
              </a>
              */}
              <a href='projects/pyxis' className='desktop-view'>
                <div className='cs-box-2 shadow'>
                  <div className='cs-content'>
                    <div className='title'>Pyxis</div>
                    <div>
                      Discover companies to invest in half the time
                  </div>
                    <div className='line mt-30' />
                    <div>
                      <a className='gradient-button gradient-button-purple hashtag'>#Web</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#Navigation</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#BigData</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#AI</a>
                    </div>
                  </div>
                  <div className='cs-img'>
                    <img src={pyxisImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                </div>
              </a>
              <a href='projects/pyxis' className='mobile-view no-underline'>
                <div className='cs-mobile-box shadow'>
                  <div>
                    <img src={pyxisImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                  <div className='cs-mobile-content-box'>
                    <div className='title'>Pyxis</div>
                    <div>
                      Discover companies to invest in half the time
                  </div>
                    <div className='hashtag gray-txt'>
                      #Web | #Navigation | #BigData | #AI
                  </div>
                  </div>
                </div>
              </a>

              {/*
              <a href='projects/parentexchange' className='desktop-view'>
                <div className='cs-box-2 shadow'>
                  <div className='cs-content'>
                    <div className='title'>Parent Exchange</div>
                    <div>
                      Search for other parents in the neighborhood and trade babysitting hours with them
                  </div>
                    <div className='line mt-30' />
                    <div>
                      <a className='gradient-button gradient-button-purple hashtag'>#Mobile</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#Childcare</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#PVP</a>
                    </div>
                  </div>
                  <div className='cs-img'>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                </div>
              </a>
              <a href='projects/parentexchange' className='mobile-view no-underline'>
                <div className='cs-mobile-box shadow'>
                  <div>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                  <div className='cs-mobile-content-box'>
                    <div className='title'>Parent Exchange</div>
                    <div>
                      Search for other parents in the neighborhood and trade babysitting hours with them
                  </div>
                    <div className='hashtag gray-txt'>
                      #Mobile | #Childcare | #PVP
                  </div>
                  </div>
                </div>
              </a>


              <a href='projects/pyxis' className='desktop-view'>
                <div className='cs-box shadow'>
                  <div className='cs-content'>
                    <div className='title'>Pyxis</div>
                    <div>
                      Find similar companies to invest in in half the time
                  </div>
                    <div className='line mt-30' />
                    <div>
                      <a className='gradient-button gradient-button-purple hashtag'>#Web</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#Investing</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#AI</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#DataVis</a>
                    </div>
                  </div>
                  <div className='cs-img'>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                </div>
              </a>
              <a href='projects/pyxis' className='mobile-view no-underline'>
                <div className='cs-mobile-box shadow'>
                  <div>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                  <div className='cs-mobile-content-box'>
                    <div className='title'>Parent Exchange</div>
                    <div>
                      Find similar companies to invest in in half the time
                  </div>
                    <div className='hashtag gray-txt'>
                      #Web | #Investing | #AI | #DataVis
                  </div>
                  </div>
                </div>
              </a>


              <a href='projects/ese' className='desktop-view'>
                <div className='cs-box-2 shadow'>
                  <div className='cs-content'>
                    <div className='title'>Ese</div>
                    <div>
                      Expedite building reports for marketers
                  </div>
                    <div className='line mt-30' />
                    <div>
                      <a className='gradient-button gradient-button-purple hashtag'>#Web</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#Mobile</a>
                      <a className='gradient-button gradient-button-purple hashtag'>#Marketing</a>
                    </div>
                  </div>
                  <div className='cs-img'>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                </div>
              </a>
              <a href='projects/ese' className='mobile-view no-underline'>
                <div className='cs-mobile-box shadow'>
                  <div>
                    <img src={ailensImg} style={{ width: '100%', height: '100%' }}></img>
                  </div>
                  <div className='cs-mobile-content-box'>
                    <div className='title'>Parent Exchange</div>
                    <div>
                      Expedite building reports for marketers
                  </div>
                    <div className='hashtag gray-txt'>
                      #Web | #Mobile | #Marketing
                  </div>
                  </div>
                </div>
              </a>
              */}

            </Col>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
          </Row>
          <Row>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
            <Col xs={10} sm={10} md={10} lg={8}>
              <div className='title section-separator'>Fun Projects</div>
            </Col>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
          </Row>
          <Row>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
            <Col xs={10} sm={10} md={10} lg={4}>
              <a href='https://tiffanyjaya-boggle.netlify.com/' target='_blank'>
                <img src={boggleImg} style={{ width: '100%', height: '100%', borderRadius: '4px' }} className='shadow' />
              </a>

            </Col>
            <Col xs={10} sm={10} md={10} lg={4} className='no-show-mobile'>
              <a href='http://tiffapedia-twitterrain.herokuapp.com/tasks/sov' target='_blank'>
                <img src={twitterrainImg} style={{ width: '100%', height: '100%', borderRadius: '4px' }} className='shadow' />
              </a>
            </Col>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
          </Row>
          <Row>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
            <Col xs={10} sm={10} md={10} lg={4} className='no-show-desktop img-separation'>
              <a href='http://tiffapedia-twitterrain.herokuapp.com/tasks/sov' target='_blank'>
                <img src={twitterrainImg} style={{ width: '100%', height: '100%', borderRadius: '4px' }} className='shadow' />
              </a>
            </Col>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
          </Row>
          <Row>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
            <Col xs={10} sm={10} md={10} lg={8}>
              <div className='section-separator'></div>
            </Col>
            <Col xs={1} sm={1} md={1} lg={2}></Col>
          </Row>
        </div>
        <div className='padding-for-footer' />
        <div className='bottom-nav'>
          <BottomNavbar />
        </div>
      </PersistentDrawerLeft>
    );
  }
}

export default HomePage;
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ImageZoom from 'react-medium-image-zoom';
//import { Document, Page, pdfjs } from 'react-pdf';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Image
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ScrollableAnchor from 'react-scrollable-anchor';
import Navbar from '../../../components/navbar/Navbar';
import Accordion from '../../../components/accordion/Accordion';
import CTAContact from '../../../components/callToAction/CTAContact2';
import Footer from '../../../components/footer/Footer';
/* CSS */
import './ParentExchange.css';
import '../CaseStudy.css';
import '../../../assets/css/Global.css';
/* Images */
import viciousCycleImg from './images/vicious-cycle.png';
import surveyScriptPage1 from './images/survey-script-pg1.png';
import surveyScriptPage2 from './images/survey-script-pg2.png';
import surveyResponsesPage1 from './images/survey-responses-pg1.png';
import surveyFindingsPage1 from './images/survey-findings-pg1.png';
import persona1 from './images/persona-1.png';
import persona2 from './images/persona-2.png';
import empathyMap1 from './images/empathy-map-1.png';
import empathyMap2 from './images/empathy-map-2.png';
import openingPage from './images/opening-page.png';
import feature1 from './images/feature1.gif';
import feature2 from './images/feature2.png';
import feature3 from './images/feature3.png';
import feature4 from './images/feature4.png';
import feature5 from './images/feature5.gif';
import sketch from './images/sketch.png';
import taskFlow from './images/task-flow.png';
import testing1 from './images/interview-1.png';
import testing2 from './images/interview-2.png';
import testing4 from './images/interview-4.png';
import testing5 from './images/interview-5.png';
import testing6 from './images/interview-6.png';
import testing7 from './images/interview-7.png';
import testing8 from './images/interview-8.png';
import testingOverviewImg from './images/interview-overview.png';
/* Files */
import surveyScriptPdf from './files/survey-script.pdf';
import surveyResponsesPdf from './files/survey-responses.pdf';
import surveyFindingsPdf from './files/survey-findings.pdf';
import testingOverviewPdf from './files/testing-overview.pdf';

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class ParentExchangePage extends React.Component {

  render() {
    return (
      <Navbar>
        <Grid fluid>
          <Row className='mt-260'>
            <Col lg={1}></Col>
            <Col lg={10} className='project-name'>Parent Exchange</Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={8} className='project-question mt-80'>
              How I help working parents<br />
              find free backup childcare
            </Col>
            <Col lg={3}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={1} className='mt-100'>
              <div className='line'></div>
            </Col>
            <Col lg={11}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-35'>
              <b>Users</b>
            </Col>
            <Col lg={7} className='mt-35'>
              Working parents
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>Sector</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Chidcare service
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>My Role</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Product designer
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>What I Delivered</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Mobile app design
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='mt-5'>
            <Col lg={1}></Col>
            <Col lg={3} >
              <b>Timeline</b>
            </Col>
            <Col lg={7}>
              4 weeks (Jun - Jul 2019)
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={10} className='mt-70 mb-50 center-txt'>
              <iframe class="invision-mobile" width="442" height="935" src="//invis.io/7VSZDW4S5QJ" frameborder="0" allowFullScreen></iframe>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={10}>
              <div className='mt-120'>
                I was curious why my friends constantly complained about the cost of childcare. Without having a child of my own, I embarked on this project and ended up designing a mobile application that enables parents to trade babysitting services with each other.
              </div>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-70'>
              Challenge
            </Col>
            <Col lg={7} className='mt-70'>
              <ol>
                <li>Understanding the challenges of the current business models in childcare services and why they fail.</li>
                <li>Building customer trust and encouraging long-term adoption of the app.</li>
                <li>Designing visual appeals that reduce stress without compromising genuineness.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-50'>
              Approach
            </Col>
            <Col lg={7} className='mt-50'>
              <ol>
                <li>Interview childcare providers and parents from Los Angeles (CA), Seattle (WA), Austin (TX), Kennesaw (GA), Bethesda (MD), Ashburn (VA), and Washington D.C. about the challenging aspects of providing and receiving childcare services.</li>
                <li>Use findings from research and interviews to guide design decisions.</li>
                <li>Iterate multiple times to unearth the true reasons why parents might not adopt the app and redesign it each time for acceptance.</li>
                <li>Documented brand guidelines to create a more consistent experience for ParentExchange users.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-50'>
              Impact
            </Col>
            <Col lg={7} className='mt-50 mb-120'>
              <ol>
                <li>Increase ease of use from 53% to 96%.</li>
                <li>Increase potential adoption rate from 20% to 45%.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='mt-120'>
            <Col lg={3}></Col>
            <Col lg={6} className='subheader blue-txt'>
              Read the full story
            </Col>
            <Col lg={3}></Col>
          </Row>
          <Row className='mt-60'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className='header'>
                Putting my project manager hat on
              </div>
              <div className='mt-30'>
                It started out as a project to satiate my inner curiosity.
                At the time, most of my friends had recently become
                first-time parents. They lamented how expensive the cost
                of childcare is and wondered if there was an alternate
                solution to this problem. So I started investigating
                by interviewing everyone who is a part of the ecosystem.
                I <b>interviewed daycare centers</b> near my
                great-aunt's home in Kennesaw, Georgia. I
                interviewed <b>licensed and unlicensed practitioners</b> in
                Austin, Texas whom I was fortunate enough to be introduced
                by a family friend who is a licensed practitioner for more
                than 18 years. Since I was going around visiting all my
                friends after graduation as a graduation gift for myself,
                I interviewed my friends and their friends who recently
                became parents.  I figured if I was designing a product
                for a company, I should interview users who live outside
                of the Bay Area bubble where I currently reside. This way
                I can get a more accurate picture of the working parents
                in America. I interviewed <b>parents</b> in the suburban areas of
                Los Angeles (CA), Seattle (WA), Ashburn (VA),
                Bethesda (MD), Washington, D.C whose kids
                are in the age range of 4 months to 5 years old.
              </div>
              <div className='mt-60'>
                <div id='map'></div>
              </div>
              <div className='mt-60'>
                What I have discovered after doing my initial research is that
                there is already a limited supply of caretakers in the industry.
                Combined with the rigorous standards defined by each state, it
                becomes difficult for daycare centers to stay afloat in the
                business. The cost of childcare is therefore passed to the
                parents who simply cannot afford to pay more because it takes
                a substantial chunk of one parent’s salary.
                What ended up happening is a catch-22.
              </div>
              <div className='mt-60 center-txt'>
                <ImageZoom
                  image={{
                    src: viciousCycleImg,
                    alt: 'Vicious Cycle',
                    style: { width: '80%' }
                  }}
                  zoomImage={{
                    src: viciousCycleImg,
                    alt: 'Vicious Cycle',
                  }}
                />
              </div>
              <div className='mt-60'>
                Parents cannot afford to pay more, which in turn reduces the ability
                of daycare centers to give a raise to caretakers. Caretakers
                whose salary had not increased in the last twenty years left
                the field, which in turn reduces the already low supply of
                caretakers.
              </div>
              <div className='mt-30'>
                <Accordion
                  heading={'Business Problems'}
                  secondaryHeading={'Market Research + Competitive Analysis'}
                  details={
                    <div>
                      <div>
                        <ol>
                          <li>High upkeep cost makes it difficult to stay afloat in a childcare business</li>
                          <li>The number of licensed caretakers are low</li>
                          <li>State laws differ for each state and add complications to running a business</li>
                        </ol>
                      </div>
                      <div className='subheader mt-60'>Problem #1: High Childcare Cost</div>
                      <div className='mt-30'>
                        As much as I wanted to find a magical solution that can
                        lighten the weight of child care costs, <b>removing daycare is not a realistic option</b>.
                        Too many parents rely on daycare as their primary care
                        at work. Plus, a solution to replace daycare requires
                        heavy investment. As one childcare provider puts it,
                      </div>
                      <div>
                        <blockquote className='mt-30'>
                          <p>
                            <q>
                              The cost (of doing business) is high. 80% (of the revenue) goes to staff. 20% (remaining) goes to (the
                              initial) startup cost. I haven’t included rent, utilities, and supplies yet.
                          </q>
                          </p>
                          <footer>
                            Administrator staff at The Foundation School
                          </footer>
                        </blockquote>
                      </div>
                      <div className='mt-30'>
                        It is no surprise then that there are <b>limited players</b> in
                        the field when the industry is trying to go digital. On-demand
                        app like the babysitter matchmaking app <a href="https://www.geekwire.com/2018/demand-childcare-startup-poppy-shuts-despite-building-rave-worthy-service-parents/" target="_blank">Poppy</a> floundered
                        three years after its founding despite several rave reviews from <a href="https://www.yelp.com/biz/poppy-seattle-2" target="_blank">Yelp</a> and
                        one parent I interviewed. The trend has even <b>shifted recently
                        from on-demand childcare services to on-demand shuttling services</b> like
                        Zum, Kango, and HopSkipDrive.
                      </div>
                      <div className='mt-30'>
                        <b>Part of the reason why we don’t see many on-demand childcare
                        services is because of the limited supplies</b>. We can see that
                        in the recent trend of on-demand shuttling. It is easier to find
                        a quality driver than it is to find a quality childcare provider.
                      </div>
                      <div className='subheader mt-60'>Problem #2: Limited Supplies</div>
                      <div className='mt-30'>
                        <b>For a product to thrive in the marketplace, the supply has
                          to be greater than the demand. But in the case of on-demand,
                          childcare service apps, the supply is frustratingly low.</b> Take for example, the leading player in this market: Care.com.
                      </div>
                      <div>
                        <blockquote className='mt-30'>
                          <p>
                            <q>
                              I expect after spending $200 to post up a job request that
                              I will find someone. It’s much better that I go to a nanny
                              agency, pay $10/day and get someone to come at a reliable
                              time than none at all.
                          </q>
                          </p>
                          <footer>
                            Sadia, a working mother of a one year old
                        </footer>
                        </blockquote>
                        <blockquote className='mt-30'>
                          <p>
                            <q>
                              It's a hit or a miss (in terms of the caretaker you're going to get).
                          </q>
                          </p>
                          <footer>
                            Vina, a working mother of a six months old
                        </footer>
                        </blockquote>
                      </div>
                      <div className='mt-30'>
                        After listening to a handful of <a href="https://medium.com/@erdorsey2/care-com-multiple-deaths-and-child-abuses-fraudulent-billing-and-a-harvey-weinstein-babysitter-7468edb54ea0" target="_blank">negative comments</a> about
                        Care.com and similar apps like it, I decided to interview
                        licensed practitioners for their perspective. To them, the cons
                        outweigh the pros.
                      </div>
                      <div>
                        <blockquote className="mt-30">
                          <p>
                            <q>
                              Who in their right mind would trade a consistent minimum wage job, with benefits mind you, for an
                              erratic
                              work schedule that is also minimum wage and without benefits?
                          </q>
                          </p>
                          <footer>
                            Mila, a licensed practitioner for over 18 years
                        </footer>
                        </blockquote>
                      </div>
                      <div className='mt-30'>
                        She is right. By the very nature of an on-demand business model, it
                        cannot guarantee a job until a customer needs it. Plus it does not
                        help that the pay is meager. $15-20/hour is the price range parents
                        I have interviewed said they are willing to pay for an on-demand
                        childcare service. For context, these parents reside in Los Angeles,
                        Seattle, and DMV area and the minimum wage for each of those
                        cities are $12, $12, and $13.25 respectively. Although these parents
                        do not represent what the general public would be willing to pay,
                        it opens my eyes to the fact that <b>there is a discrepancy between
                          what parents want to pay and what caretakers expect to get as pay
                          for their services</b>.
                      </div>
                      <div className='subheader mt-60'>Problem #3: State Laws</div>
                      <div className='mt-30'>
                        Business in this field is further complicated by state <b>law that
                        changes depending on the state parents reside in</b>. Since
                        I intend to build a business around an on-demand, childcare
                        service app, I have to ensure that whatever I am building aligns
                        with the regulations that companies faced in this industry. Usually
                        I would talk to the project manager or stakeholders to confirm the
                        business direction, but seeing that this is a personal project, I
                        thoroughly researched state laws to ensure that the app fully
                        complies to them.
                      </div>
                      <div>
                        <blockquote class="mt-30">
                          <p>
                            <q>
                              You are at the whim of local authorities. They can close you down if you are caught breaking a law and
                              it
                              is not fixed before the next day.
                          </q>
                          </p>
                          <footer>
                            Administrator staff at The Foundation School
                        </footer>
                        </blockquote>
                      </div>
                    </div>
                  }
                  defaultExpanded={false}
                />
              </div>
              <div>
                <Accordion
                  heading={'User Problems'}
                  secondaryHeading={'User Research + User Interviews'}
                  details={
                    <div>
                      <div>
                        <ol>
                          <li>Cost of childcare eats up at least 50% of one parent's salary</li>
                          <li>Finding a good caretaker is hard because there's so few of them</li>
                          <li>The process is highly stressful because combing through a list of childcare providers is a tedious process and requires a lot of time</li>
                        </ol>
                      </div>
                      <div className='subheader mt-60'>Problem #1: High Childcare Cost</div>
                      <div className='mt-30'>
                        It is unfortunate to say this but the more I interviewed the users,
                        the more I learned that <b>the problems faced by the business trickle down to the users</b>. <b>Cost is a big concern by both business and users</b>. This
                        is partially due to our system in the United States expecting
                        child care providers and parents to foot most of the bill instead
                        of the government. To give some context, the average cost of
                        childcare ended up approximating to $2000 per child or at least
                        50% of one parent’s salary according to the parents I have
                        interviewed (which is not too far of from the average of 65%
                        nationally). That is higher than what the <a href="https://www.epi.org/child-care-costs-in-the-united-states/" target="_blank">U.S. Department of Health and Human Services recommends</a>.
                        According to the U.S. Department of Health and Human Services
                        standard, any child care that costs more than 7% of a family’s
                        income is considered unaffordable. 50% is seven times more than
                        the recommended threshold!
                      </div>
                      <div className='subheader mt-60'>Problem #2: Lack of Availability & Quality</div>
                      <div className='mt-30'>
                        <b>As a result of high cost, parents cannot afford to pay more for caretakers</b>.
                        One caretaker I have interviewed has a license, a Bachelor’s
                        degree, and a certification to teach a Montessori program.
                        In her 20+ years as a licensed practitioner, her pay had not
                        increased above $18/hour. <b>With not enough pay, not many are willing to go into the field of childcare</b>.
                        It comes to no surprise then that lack of availability and
                        lack of quality are the next follow up complaints parents
                        say about childcare.
                      </div>
                      <div className='subheader mt-60'>Problem #3: Stress</div>
                      <div className='mt-30'>
                        <b>All these factors combined make the search for the right childcare provider a stressful one</b>.
                        It is one of the reason why <a href='https://winnie.com/childcare'>Winnie</a>,
                        a website and mobile application that discover the best childcare,
                        is so popular.
                      </div>
                    </div>
                  }
                  defaultExpanded={false}
                />
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
          <Row className='mt-120 navy-bg'>
            <Col lg={2}></Col>
            <Col lg={8}>
              <div className='mt-90 subheader carousel-container'>
                <div className='carousel-left-container'></div>
                <div className='carousel-center-container'>
                  User Survey Artifacts
                </div>
              </div>
              <div className='mb-90'>
                <CarouselProvider
                  currentSlide={0}
                  visibleSlides={2}
                  totalSlides={4}
                  naturalSlideWidth={400}
                  naturalSlideHeight={500}
                  step={2}
                  infinite={true}
                  isPlaying={true}
                  interval={5000}
                  hasMasterSpinner
                  lockOnWindowScroll
                  className='carousel-container'
                >
                  <ButtonBack className='carousel-left-container fa fa-arrow-circle-o-left'>	</ButtonBack>
                  <Slider className='carousel-center-container'>
                    <Slide index={0}>
                      <ImageZoom
                        image={{
                          src: surveyScriptPage1,
                          alt: 'Script for parents',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: surveyScriptPage1,
                          alt: 'Script for parents',
                        }}
                      />
                    </Slide>
                    <Slide index={1} >
                      <ImageZoom
                        image={{
                          src: surveyScriptPage2,
                          alt: 'Script for childcare provider',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: surveyScriptPage2,
                          alt: 'Script for childcare provider',
                        }}
                      />
                    </Slide>
                    <Slide index={2}>
                      <a href={surveyResponsesPdf} target='_blank'>
                        <Image src={surveyResponsesPage1} className='zoom-in-cursor' />
                      </a>
                    </Slide>
                    <Slide index={3}>
                      <a href={surveyFindingsPdf} target='_blank'>
                        <Image src={surveyFindingsPage1} className='zoom-in-cursor' />
                      </a>
                    </Slide>
                  </Slider>
                  <ButtonNext className='carousel-right-container fa fa-arrow-circle-o-right'></ButtonNext>
                  <DotGroup
                    className='carousel-dot-group'
                  />
                </CarouselProvider>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row className='mt-120'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className='header'>
                Why I chose to build an app with parents as caretakers
              </div>
              <div className='mt-60 subheader'>
                From the business perspective
              </div>
              <div className='mt-30'>
                In the limited timespan I set for myself (approximately a month),
                I decided not to tackle the problem of daycare centers. It is a
                business model in and of itself and would require additional 60-80
                interviews to validate the solution. Instead I set out to solve the
                problem of on-demand childcare apps.
              </div>
              <div className='mt-30'>
                Currently on-demand apps rely on matching caretakers to parents, but
                this business model is flawed. <b>Caretakers are not willing to give up
                their full time employment</b> with benefits for an unknown work schedule
                without benefits. Because of this, there are <b>limited supply</b> in quality,
                licensed practitioner. I overcome this problem by enabling parents to
                be caretakers themselves.
              </div>
              <div className='mt-30'>
                As an added bonus, by having a parent be a caretaker of another parent’s
                children, it complies to the majority of state laws in the United
                States. Parents are not required to get a license if they only took
                care of one family’s children. This will save ParentExchange from
                disputing regulations with local governments that “sharing economy”
                companies seem to face, i.e. Airbnb, Uber, Lyft.
              </div>
              <div className='mt-60 subheader'>
                From the user perspective
              </div>
              <div className='mt-30'>
                It also addresses some concerns parents often mentioned in passing,
                which is the <b>lack of resources</b> they found <b>in backup childcare</b>.
                As of late June 2019, when I completed my user surveys, parents resorted to
                Facebook groups to trade babysitting services with each other. It was
                tedious because of the multiple back and forth in confirmation. <b>At the time,
                there was not a solution in the market to address this</b>. For
                this reason, I decided to design a backup childcare app that emulates this
                service. I focused on mobile application specifically because most parents
                reached out to their phone first when I asked them to walk me through how
                they would go about scheduling a backup childcare.
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row >

          <Row className='mt-60'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className='header'>
                The importance of selecting the right target segment
              </div>
              <div className='mt-30'>
                I learned that income level, work flexibility, and
                age of child play a huge role in the adoption of
                the app.
                <ul>
                  <li className='icon-income-level'>
                    Those who can afford it prefer if they can hire
                    their backup childcare. It is more convenient
                    to their schedule.
                  </li>
                  <ul>
                    <li>
                      Focus instead on low middle-income parents
                      in Facebook groups who are already trading
                      babysitting hours with each other.
                    </li>
                  </ul>
                  <li className='icon-flexible-work'>
                    Those who can tag team with their spouse or have
                    the flexibility at work to take leave as needed
                    do not find the need to use backup childcare.
                  </li>
                  <ul>
                    <li>
                      Focus instead on parents who do not have flexibility
                      as a luxury.
                    </li>
                  </ul>
                  <li className='icon-age-of-child'>
                    Those whose first-born are younger than the age
                    of 5 express concerns having other parents take
                    care of their child.
                  </li>
                  <ul>
                    <li>
                      Focus instead on parents having children above
                      the age of 5.
                    </li>
                  </ul>

                </ul>
              </div>
              <div className='mt-30'>
                Once I took this into consideration, I noticed early on that there
                are two sets of camp that can benefit most from this. The first
                are <b>mothers who have consistent activities</b>, and the second
                are <b>single mothers</b>. I focused on mothers in particular
                because most parents I have surveyed said
              </div>
              <div className='mt-30'>
                <blockquote>
                  <p>
                    <q>
                      Mama Bear has the final say in what [childcare
                      provider] we're using
                    </q>
                  </p>
                  <footer>
                    Alvin, father of 3 years old + newborn
                  </footer>
                </blockquote>
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
          <Row className='mt-120 navy-bg'>
            <Col lg={2}></Col>
            <Col lg={8}>
              <div className='mt-90 subheader carousel-container'>
                <div className='carousel-left-container'></div>
                <div className='carousel-center-container'>
                  Persona + Job Stories + Empathy Map
                </div>
              </div>
              <div className='mb-90'>
                <CarouselProvider
                  currentSlide={0}
                  visibleSlides={1}
                  totalSlides={5}
                  naturalSlideWidth={500}
                  naturalSlideHeight={400}
                  step={1}
                  infinite={true}
                  isPlaying={true}
                  interval={5000}
                  hasMasterSpinner
                  lockOnWindowScroll
                  className='carousel-container'
                >
                  <ButtonBack className='carousel-left-container fa fa-arrow-circle-o-left'>	</ButtonBack>
                  <Slider className='carousel-center-container'>
                    <Slide index={0}>
                      <Image src={openingPage} />
                    </Slide>
                    <Slide index={1}>
                      <ImageZoom
                        image={{
                          src: persona1,
                          alt: 'Persona of mothers who have consistent activities',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: persona1,
                          alt: 'Persona of mothers who have consistent activities',
                        }}
                      />
                    </Slide>
                    <Slide index={2} >
                      <ImageZoom
                        image={{
                          src: empathyMap1,
                          alt: 'Empathy map of mothers who have consistent activities',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: empathyMap1,
                          alt: 'Empathy map of mothers who have consistent activities',
                        }}
                      />
                    </Slide>
                    <Slide index={3}>
                      <ImageZoom
                        image={{
                          src: persona2,
                          alt: 'Persona of single mothers',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: persona2,
                          alt: 'Persona of single mothers',
                        }}
                      />
                    </Slide>
                    <Slide index={4}>
                      <ImageZoom
                        image={{
                          src: empathyMap2,
                          alt: 'Empathy map of single mothers',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: empathyMap2,
                          alt: 'Empathy map of single mothers',
                        }}
                      />
                    </Slide>
                  </Slider>
                  <ButtonNext className='carousel-right-container fa fa-arrow-circle-o-right'></ButtonNext>
                  <DotGroup
                    className='carousel-dot-group'
                  />
                </CarouselProvider>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row className='mt-120'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className='header'>
                Scheduling as the main feature requirement
              </div>
              <div className='mt-30'>
                After talking to parents, I derived the following insights
                that define the key features of the product.
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
          <Row className='mt-60'>
            <Col lg={2}></Col>
            <Col lg={3}>
              <ImageZoom
                image={{
                  src: feature1,
                  alt: 'Feature 1',
                  className: 'rounded-corners shadow',
                  style: { width: '100%' }
                }}
                zoomImage={{
                  src: feature1,
                  alt: 'Feature 1',
                }}
              />
            </Col>
            <Col lg={1}></Col>
            <Col lg={4}>
              <div className='vertical-center'>
                <div className='subheader'>
                  Dive straight to scheduling
              </div>
                <div className='mt-30'>
                  When parents download an app, they want to be able to see
                  what they can do with the app without heavy on-boarding.
              </div>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row className='mt-60'>
            <Col lg={2}></Col>
            <Col lg={3}>
              <ImageZoom
                image={{
                  src: feature2,
                  alt: 'Feature 2',
                  className: 'rounded-corners shadow',
                  style: { width: '100%' }
                }} feature2
                zoomImage={{
                  src: feature2,
                  alt: 'Feature 2',
                }}
              />
            </Col>
            <Col lg={1}></Col>
            <Col lg={4}>
              <div className='vertical-center'>
                <div className='subheader'>
                  Schedule immediately + schedule recurringly
              </div>
                <div className='mt-30'>
                  <i>Single Mother Persona</i>:<br />Schedule immediately
                  for parents who need backup childcare
                  right away.
                </div>
                <div className='mt-30'>
                  <i>Mother with Recurring Activities Persona</i>:<br /> Schedule
                  recurringly for parents who have consistent
                  activities scheduled.
                </div>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row className='mt-60'>
            <Col lg={2}></Col>
            <Col lg={3}>
              <ImageZoom
                image={{
                  src: feature3,
                  alt: 'Feature 3',
                  className: 'rounded-corners shadow',
                  style: { width: '100%' }
                }}
                zoomImage={{
                  src: feature3,
                  alt: 'Feature 3',
                }}
              />
            </Col>
            <Col lg={1}></Col>
            <Col lg={4}>
              <div className='vertical-center'>
                <div className='subheader'>
                  Have a review page to alleviate concern
                </div>
                <div className='mt-30'>
                  Parents selected child care providers based on recommendations
                  either from third-party reviews or from friends. A review page
                  is one way we can alleviate parents’ concern about the safety
                  of their children.
                </div>
                <div className='mt-30'>
                  The app will also try to match parents whose children are of
                  comparable age similar to how parents select other parents
                  based on their children's age in play dates.
                </div>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row className='mt-60'>
            <Col lg={2}></Col>
            <Col lg={3}>
              <ImageZoom
                image={{
                  src: feature4,
                  alt: 'Feature 4',
                  className: 'rounded-corners shadow',
                  style: { width: '100%' }
                }}
                zoomImage={{
                  src: feature4,
                  alt: 'Feature 4',
                }}
              />
            </Col>
            <Col lg={1}></Col>
            <Col lg={4}>
              <div className='vertical-center'>
                <div className='subheader'>
                  Be able to communicate with the parent first before scheduling the swap
                </div>
                <div className='mt-30'>
                  Before they babysit each other’s children, parents typically want to talk
                  to each other and sometimes meet in person.
              </div>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row className='mt-60'>
            <Col lg={2}></Col>
            <Col lg={3}>
              <ImageZoom
                image={{
                  src: feature5,
                  alt: 'Feature 5',
                  className: 'rounded-corners shadow',
                  style: { width: '100%' }
                }}
                zoomImage={{
                  src: feature5,
                  alt: 'Feature 5',
                }}
              />
            </Col>
            <Col lg={1}></Col>
            <Col lg={4}>
              <div className='vertical-center'>
                <div className='subheader'>
                  Incorporate the ability for parents to see the welfare of
                  their child
              </div>
                <div className='mt-30'>
                  I noticed during interviews that even though gyms and
                  supermarkets now have the option for you to drop off
                  your child in a temporary daycare service, most mothers
                  I have talked to are anxious about the well-being of
                  their child that they ended up not fully completing
                  the task they set out to do or in the case of the gym,
                  some moms ended up exercising really close to the
                  daycare centers where they can peer through the glass
                  windows. It was not explicitly implied that parents
                  needed this feature, but I understand it will give
                  them a peace of mind if they wanted to use ParentExchange
                  as their backup childcare of choice.
              </div>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row className='mt-120 navy-bg'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className='mt-90 subheader'>Sketch the prototype</div>
              <div className='mt-30'>
                Having defined what the users expect out of the app,
                I sketched out the prototype.
                  </div>
              <div className='mt-30 mb-90'>
                <img src={sketch} alt='sketch prototype' style={{ width: '100%' }} />
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
          <Row className='mt-120'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className='subheader'>Scheduling task flow</div>
              <div className='mt-30'>
                I then emulated ParentExchange similar to how a parent would
                schedule a babysitting swap with another parent. I also made
                sure that there is a back button for each page that requires
                successive inputs. Mistakes inevitably happen. I want to give
                parents the option to go back and revise their answer.
              </div>
              <div className='mt-30'>
                Here is the finalized scheduling task flow:
              </div>
              <div className='mt-30'>
                <img src={taskFlow} alt='sketch prototype' style={{ width: '100%' }} />
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
          {/*
          <Row className='mt-120'>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className='header'>
                Iteratively test and revise to increase adoption rate
              </div>
              <div className='mt-30'>
                ...
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
          */}
          <Row className='mt-120 navy-bg'>
            <Col lg={2}></Col>
            <Col lg={8}>
              <div className='mt-90 subheader carousel-container'>
                <div className='carousel-left-container'></div>
                <div className='carousel-center-container'>
                  Usability Testing Artifacts
                </div>
              </div>
              <div className='mb-90'>
                <CarouselProvider
                  currentSlide={0}
                  visibleSlides={1}
                  totalSlides={8}
                  naturalSlideWidth={400}
                  naturalSlideHeight={500}
                  step={1}
                  infinite={true}
                  isPlaying={true}
                  interval={5000}
                  hasMasterSpinner
                  lockOnWindowScroll
                  className='carousel-container'
                >
                  <ButtonBack className='carousel-left-container fa fa-arrow-circle-o-left'>	</ButtonBack>
                  <Slider className='carousel-center-container'>
                    <Slide index={0}>
                      <a href={testingOverviewPdf} target='_blank'>
                        <Image src={testingOverviewImg} className='zoom-in-cursor' />
                      </a>
                    </Slide>
                    <Slide index={1} >
                      <ImageZoom
                        image={{
                          src: testing1,
                          alt: 'Test #1',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: testing1,
                          alt: 'Test #1',
                        }}
                      />
                    </Slide>
                    <Slide index={2}>
                      <ImageZoom
                        image={{
                          src: testing2,
                          alt: 'Test #2',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: testing2,
                          alt: 'Test #2',
                        }}
                      />
                    </Slide>
                    <Slide index={3}>
                      <ImageZoom
                        image={{
                          src: testing4,
                          alt: 'Test #4',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: testing4,
                          alt: 'Test #4',
                        }}
                      />
                    </Slide>
                    <Slide index={4}>
                      <ImageZoom
                        image={{
                          src: testing5,
                          alt: 'Test #5',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: testing5,
                          alt: 'Test #5',
                        }}
                      />
                    </Slide>
                    <Slide index={5}>
                      <ImageZoom
                        image={{
                          src: testing6,
                          alt: 'Test #6',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: testing6,
                          alt: 'Test #6',
                        }}
                      />
                    </Slide>
                    <Slide index={6}>
                      <ImageZoom
                        image={{
                          src: testing7,
                          alt: 'Test #7',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: testing7,
                          alt: 'Test #7',
                        }}
                      />
                    </Slide>
                    <Slide index={6}>
                      <ImageZoom
                        image={{
                          src: testing8,
                          alt: 'Test #8',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: testing8,
                          alt: 'Test #8',
                        }}
                      />
                    </Slide>
                  </Slider>
                  <ButtonNext className='carousel-right-container fa fa-arrow-circle-o-right'></ButtonNext>
                  <DotGroup
                    className='carousel-dot-group'
                  />
                </CarouselProvider>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>

          <Row className='mt-120 cta-bg'>
            <Col lg={12}>
              <CTAContact />
            </Col>
          </Row>
          <Row>
            <Col lg={11}>
              <Footer />
            </Col>
          </Row>
        </Grid >
      </Navbar >
    )
  }
}

export default ParentExchangePage;
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Navbar from '../../../components/navbar/Navbar';
import CTAContact from '../../../components/callToAction/CTAContact2';
import Footer from '../../../components/footer/Footer';
/* CSS */
import '../CaseStudy.css';
import '../../../assets/css/Global.css';
/* Images */
import sovImg from './images/final-design-sov.png';


class TwitterrainPage extends React.Component {

  render() {
    return (
      <Navbar>
        <Grid fluid>
          <Row className='mt-260'>
            <Col lg={1}></Col>
            <Col lg={10} className='project-name'>Twitterrain</Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={8} className='project-question mt-80'>
              How can we help<br />
              marketers gain insight<br />
              into their customers?
          </Col>
            <Col lg={3}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={10} className='project-type mt-50'>
              SaaS B2B
          </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={1} className='mt-100'>
              <div className='line'></div>
            </Col>
            <Col lg={11}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-35'>
              <b>Users</b>
            </Col>
            <Col lg={7} className='mt-35'>
              Marketers
          </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>Sector</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Marketing
          </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>My Role</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Project manager, full stack engineer, data visualization engineer, product designer
          </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>What I Delivered</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Desktop prototype
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={10} className='mt-80 mb-120 center-txt'>
              <img src={sovImg} alt='Final Design' className='rounded-corners shadow' style={{ width: '75%' }} />
              <div className='mt-40 center-txt'>
                <a
                  className='gradient-button gradient-button-pink case-study-button'
                  href='http://tiffapedia-twitterrain.herokuapp.com/tasks/sov'
                  target='_blank' rel="noopener noreferrer"
                >
                  View Prototype
                </a>
              </div>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={10}>
              <div className='mt-120'>
                For my data visualization class in UC Berkeley, I teamed up with two other data scientists to design and build a data visualization platform that can help marketers gain insight into their customers and competitors using Twitter data.
              </div>
            </Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-70'>
              Challenge
          </Col>
            <Col lg={7} className='mt-70'>
              <ol>
                <li>Design a data visualization platform based on what is available on Twitter’s freemium data access.</li>
                <li>Figure out a differentiating factor between Twitterrain and other marketing platforms out in the market.</li>
                <li>Insights extracted from the dataset have to be clearly explained in a language marketers can understand.</li>
                <li>Programmed a framework where team members can quickly plug and play what they have created with D3.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-50'>
              Approach
          </Col>
            <Col lg={7} className='mt-50'>
              <ol>
                <li>Performed market research and user interviews to determine features most necessary for marketers to accomplish their tasks.</li>
                <li>Presented design iterations to professor for improvement feedback.</li>
                <li>Tested design with marketers to discern user feasibility and then tested the prototype with engineers to see if it’s technically sound.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-50'>
              Impact
          </Col>
            <Col lg={7} className='mt-50 mb-120'>
              I learned how to build an end-to-end product. The software engineering motto “test quickly, test often” aptly applies to design as well since hypothesized features that were thought of as great are not always as great when presented in front of the user.
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={10}>
              <div className='mt-70 mb-70 center-txt'>Detail writeup coming soon.</div>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='mt-120 cta-bg'>
            <Col lg={12}>
              <CTAContact />
            </Col>
          </Row>
          <Row>
            <Col lg={11}>
              <Footer />
            </Col>
          </Row>
        </Grid >
      </Navbar>
    )
  }
}

export default TwitterrainPage;
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ResumeIcon from '@material-ui/icons/MoveToInbox';
import ContactIcon from '@material-ui/icons/Mail';
import AboutIcon from '@material-ui/icons/Face';
import ProjectsIcon from '@material-ui/icons/ImportantDevices';
import CreditsIcon from '@material-ui/icons/Loyalty';
import BlogsIcon from '@material-ui/icons/ArtTrack';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { SocialIcon } from 'react-social-icons';

/* CSS */
import '../../assets/css/Global.css';
import './Navbar.css';
/* Images */
import logo from '../../assets/images/white-logo.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'sticky',
  },
  navbar: {
    background: '#FFFFFF',
    boxShadow: 'none',
  },
  logo: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '700',
    fontSize: '28px',
    lineHeight: '33px',
    color: '#111F3B',
  },
  logoImg: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  link: {
    marginRight: theme.spacing(4),
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    textTransform: 'capitalize',
    color: '#111F3B',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#111F3B',
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerIcon: {
    color: '#111F3B',
  }
}));

export default function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const selectDrawerIcon = (index) => {
    switch (index) {
      case 0:
        return <ResumeIcon style={{ width: 25, height: 25 }} />
      case 1:
        return <ProjectsIcon style={{ width: 25, height: 25 }} />
      case 2:
        return <BlogsIcon style={{ width: 25, height: 25 }} />
      case 3:
        return <AboutIcon style={{ width: 25, height: 25 }} />
      case 4:
        return <ContactIcon style={{ width: 25, height: 25 }} />
      case 5:
        return <CreditsIcon style={{ width: 25, height: 25 }} />

      default:
        return null;
    }
  }
  const selectDrawerText = (index) => {
    switch (index) {
      case 0:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://drive.google.com/file/d/14I5m0j7NV09B0bfi7T5By5mhp4Q1VRVS/view' target='_blank' className='link'>Resume</a>
          </Link>
        );

      case 1:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='/projects/' className='link'>Projects</a>
          </Link>
        );
      case 2:
        return (
          <Link color='inherit' className={classes.link}>
            {
              /* 
              <a href='/blogs/' className='link'>Blogs</a>
              */
            }
            <a href='https://medium.com/@tiffanyjaya' className='link' target='_blank'>Blogs</a>
          </Link>
        );
      case 3:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='/about/' className='link'>About</a>
          </Link>
        );
      case 4:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://tiffanyjaya.typeform.com/to/R6ZB50' target='_blank' className='link'>Contact</a>
          </Link>
        );
      case 5:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='/credits/' className='link'>Credits</a>
          </Link>
        );
      default:
        return null;
    }
  }
  const selectSocialMediaIcon = (index) => {
    switch (index) {
      case 0:
        return <SocialIcon url='https://www.linkedin.com/in/tiffanyjaya/' style={{ width: 25, height: 25 }} />
      case 1:
        return <SocialIcon url='https://medium.com/@tiffanyjaya' style={{ width: 25, height: 25 }} />
      case 2:
        return <SocialIcon url='https://github.com/tiffanyjaya' style={{ width: 25, height: 25 }} />
      case 3:
        return <SocialIcon url='https://www.behance.net/tiffanyjaya' style={{ width: 25, height: 25 }} />
      case 4:
        return <SocialIcon url='https://www.pinterest.com/tiffapedia/' style={{ width: 25, height: 25 }} />
      case 5:
        return <SocialIcon url='https://www.instagram.com/tiffapedia/?hl=en' style={{ width: 25, height: 25 }} />
      case 6:
        return <SocialIcon url='https://twitter.com/tiffapedia' style={{ width: 25, height: 25 }} />
      default:
        return null;
    }
  }
  const selectSocialMediaText = (index) => {
    switch (index) {
      case 0:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://www.linkedin.com/in/tiffanyjaya/' target='_blank' className='link'>LinkedIn</a>
          </Link>
        );

      case 1:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://medium.com/@tiffanyjaya' target='_blank' className='link'>Medium</a>
          </Link>
        );
      case 2:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://github.com/tiffanyjaya' target='_blank' className='link'>GitHub</a>
          </Link>
        );
      case 3:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://www.behance.net/tiffanyjaya' target='_blank' className='link'>Behance</a>
          </Link>
        );
      case 4:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://www.pinterest.com/tiffapedia/' target='_blank' className='link'>Pinterest</a>
          </Link>
        );
      case 5:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://www.instagram.com/tiffapedia/?hl=en' target='_blank' className='link'>Instagram</a>
          </Link>
        );
      case 6:
        return (
          <Link color='inherit' className={classes.link}>
            <a href='https://twitter.com/tiffapedia' target='_blank' className='link'>Twitter</a>
          </Link>
        );
      default:
        return null;
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.navbar}>
        <Toolbar>
          <IconButton edge='start' className={classes.logo} onClick={() => { window.location.href = '/' }}>
            <img src={logo} alt='logo' className={classes.logoImg} />
            Tiffany Jaya<span className='pink-txt'>.</span>
          </IconButton>
          <Typography variant='h6' className={classes.title}>
          </Typography>
          <Link color='inherit' className={classes.link}>
            <a href='https://drive.google.com/file/d/1XqS-qLSpwafu7o0yKZj0xYfazRHfPASn/view?usp=sharing' target='_blank' className='link'>Resume</a>
          </Link>
          <Link color='inherit' className={classes.link}>
            <a href='/projects/' className='link'>Projects</a>
          </Link>
          <Link color='inherit' className={classes.link}>
            <a href='/about/' className='link'>About</a>
          </Link>
          <Link color='inherit' className={classes.link}>
            <a href='https://tiffanyjaya.typeform.com/to/R6ZB50' target='_blank' className='link'>Contact</a>
          </Link>
          <IconButton
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            edge='end'
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar >
      {children}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Resume', 'Projects', 'Blogs', 'About', 'Contact'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon className={classes.drawerIcon}>
                {
                  selectDrawerIcon(index)
                }
              </ListItemIcon>
              <ListItemText primary=
                {
                  selectDrawerText(index)
                }
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['LinkedIn', 'Medium', 'GitHub', 'Behance', 'Pinterest', 'Instagram', 'Twitter'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon className={classes.drawerIcon}>
                {
                  selectSocialMediaIcon(index)
                }
              </ListItemIcon>
              <ListItemText primary=
                {
                  selectSocialMediaText(index)
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div >
  );
}


import React from 'react';
import nextId from 'react-id-generator';
import { Grid, Row, Col } from 'react-flexbox-grid';


class KausPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [
        { id: nextId(), name: 'apple', price: '$2.50' },
        { id: nextId(), name: 'orange', price: '$3.00' },
        { id: nextId(), name: 'grape', price: '$4.25' }
      ],
      cart: [],
    }
  }

  addToCart = (itemIndex) => {
    const item = this.state.items[itemIndex];
    var cart = [...this.state.cart];
    cart.push(item);
    this.setState({ cart: cart });



    console.log('add to cart', item);
    console.log('cart', cart);
  }

  render() {
    return (
      <Grid fluid>
        Cart: {this.state.cart.length}

        <Row>
          <Col xs={1} sm={1} md={1} lg={1}></Col>
          <Col xs={2} sm={2} md={2} lg={2}>Id</Col>
          <Col xs={2} sm={2} md={2} lg={2}>Name</Col>
          <Col xs={2} sm={2} md={2} lg={2}>Price</Col>
        </Row>
        {
          this.state.items.map((item, index) => (
            <Row>
              <Col xs={1} sm={1} md={1} lg={1}></Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                {item.id}
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                {item.name}
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                {item.price}
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                < button onClick={this.addToCart.bind(this, index)} > Add Item</button>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}></Col>
            </Row>
          ))
        }
      </Grid >
    );
  }
}

export default KausPage;
import React, { Component } from "react"
import "./App.css"
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactGa from 'react-ga';
import { createBrowserHistory } from 'history';
import HomePage from '../../routes/home/index';
import AboutPage from '../../routes/about/index';
import AILensLockedPage from '../../routes/projects/ai-lens-locked/index';
import PyxisPage from '../../routes/projects/pyxis/index';

import EsePage from '../../routes/projects/ese/index';
import ParentExchangePage from '../../routes/projects/parentexchange/index';
import TwitterrainPage from '../../routes/projects/twitterrain/index';
import KausPage from '../../routes/projects/kaus/index';


ReactGa.initialize('UA-145060459-1');
const history = createBrowserHistory()
history.listen((location, action) => {
  // update user's current page
  ReactGa.set({ page: location.pathname });
  // record a pageview for the given page
  ReactGa.pageview(location.pathname);
});

ReactGa.set({ page: window.location.pathname });
ReactGa.pageview(window.location.pathname);

class App extends Component {

  render() {
    return (
      <BrowserRouter history={history}>
        <Switch>
          <Route
            exact path='/'
            render={(routeProps) => (<HomePage {...routeProps}{...this.props} />)}
          />
          <Route
            exact path='/about/'
            render={(routeProps) => (<AboutPage {...routeProps}{...this.props} />)}
          />
          <Route
            exact path='/projects/pyxis/'
            render={(routeProps) => (<PyxisPage {...routeProps}{...this.props} />)}
          />
          <Route
            exact path='/projects/ai-lens/'
            render={(routeProps) => (<AILensLockedPage {...routeProps}{...this.props} />)}
          />
          <Route
            exact path='/projects/ese/'
            render={(routeProps) => (<EsePage {...routeProps}{...this.props} />)}
          />
          <Route
            exact path='/projects/parentexchange/'
            render={(routeProps) => (<ParentExchangePage {...routeProps}{...this.props} />)}
          />
          <Route
            exact path='/projects/twitterrain/'
            render={(routeProps) => (<TwitterrainPage {...routeProps}{...this.props} />)}
          />
          <Route
            exact path='/projects/kaus/'
            render={(routeProps) => (<KausPage {...routeProps}{...this.props} />)}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App

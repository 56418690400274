import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PersistentDrawerLeft from '../../components/navbar/PersistentDrawerLeft';
import BottomNavbar from '../../components/navbar/BottomNavbar';
import Testimonial from '../../components/testimonial/Testimonial';
import AboutMeAccordion from '../../components/accordion/AboutMeAccordion';
import CTAContact from '../../components/callToAction/CTAContact';
import Footer from '../../components/footer/Footer';
/* CSS */
import './about.css';
import '../../assets/css/Global.css';
/* Images */
import dogeImg from './images/doge.jpg';
import meekoImg from './images/meeko.jpg';
import usImg from './images/hubby_and_i.jpg';

class AboutPage extends React.Component {

  render() {
    return (
      <PersistentDrawerLeft isMobile={true} hasMenu={false} isDark={false}>
        <div className='white-bg'>
          <Row>
            <Col xs={1} sm={1} md={1} lg={1}></Col>
            <Col xs={10} sm={10} md={10} lg={10}>
              <div className='project-title top-3em'>
                About Me
              </div>
              <div className='project-objective about-me-headline'>
                <div>I do not simply design a beautiful product.</div>
                <div className='add-padding-mobile'>I design to solve the <span className='pink-txt'>core problem</span> your business is facing.</div>
              </div>
              <Row className='triangle-bg center-img-hv add-in-desktop'>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={12} sm={12} md={3} lg={3} className='no-show-mobile'>
                  <img src={dogeImg} alt='Doge My Cat' className='top-3em' style={{ width: '100%' }} />
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <img src={usImg} alt='Me' style={{ width: '100%' }} />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} className='no-show-mobile'>
                  <img src={meekoImg} alt='Meeko My Cat' className='top-3em no-show-mobile' style={{ width: '100%' }} />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
              </Row>
              <Row>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={12} sm={12} md={10} lg={10}>
                  <div className='subheader top-4em'>
                    Why you need a product designer more so than you realize
                </div>
                  <div className='paragraph bottom-4em'>
                    What I have come to realize after spending some time as
                    a software engineer and a data scientist is that if we
                    did not take the time to listen to our users and their
                    needs, we will waste our time building a product they
                    simply do not care about. This is where a product designer
                    steps in. I design experiences that delight your customers
                    but take into consideration your team’s time and budget.
                    This is why I strive to become a product designer because
                    I care about designing experiences that respect everyone
                    involved.
                  </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
              </Row>
              <div className='light-blue-bg'>
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1}></Col>
                  <Col xs={10} sm={10} md={10} lg={10}>
                    <div className='subheader top-4em'>
                      In the past ...
                    </div>
                    <div className='paragraph bottom-4em no-show-mobile'>
                      <ul>
                        <li>I helped Fractl’s clients engage their readers via interactive data visualizations.</li>
                        <li>I refactored EatClub’s lunch selection interface. </li>
                        <li>I implemented multiple Lab41 open source projects that pushed the boundaries of what machine learning models can do.</li>
                        <li> I listened and addressed the mobile security concerns Federal Government had.</li>
                      </ul>
                    </div>
                    <div className='paragraph bottom-4em no-show-desktop'>
                      <p>I helped Fractl’s clients engage their readers via interactive data visualizations.</p>
                      <p>I refactored EatClub’s lunch selection interface. </p>
                      <p>I implemented multiple Lab41 open source projects that pushed the boundaries of what machine learning models can do.</p>
                      <p> I listened and addressed the mobile security concerns Federal Government had.</p>
                    </div>

                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}></Col>
                </Row>
              </div>
              <Row>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={12} sm={12} md={10} lg={10}>
                  <div className='subheader top-4em'>
                    Explore the different facets of me
                  </div>
                  <div className='top-1p5em' >
                    <AboutMeAccordion />
                  </div>
                  <div className='top-1p5em bottom-4em'>
                    If you are interested in hiring me <b>full time in the Bay Area</b> or
                    have a <b>remote part-time work</b> in mind, feel free to reach
                out to me.                                                                                                                                                                   I look forward to it!
                  </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
              </Row>

              <Col xs={1} sm={1} md={1} lg={1}></Col>
            </Col>
          </Row>
        </div>
        <div className='padding-for-footer' />
        <div className='bottom-nav'>
          <BottomNavbar />
        </div>
      </PersistentDrawerLeft >
    )
  }
}

export default AboutPage;
import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './PersistentDrawerLeft.css';
import darkLogo from '../../assets/images/dark-logo.png';
import lightLogo from '../../assets/images/white-logo.png';

const drawerWidth = 240;
const mobileDrawerWidth = 120;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarDark: {
    backgroundColor: '#111F3B',
  },
  appBarLight: {
    backgroundColor: '#FFFFFF',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mobileAppBarShift: {
    width: `calc(100% - ${mobileDrawerWidth}px)`,
    marginLeft: mobileDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  mobileDrawer: {
    width: mobileDrawerWidth,
    flexShrink: 0,
  },
  mobileDrawerPaper: {
    width: mobileDrawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  mobileContent: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -mobileDrawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(!props.isMobile && props.hasMenu);
  const { children } = props;


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, props.isDark ? classes.appBarDark : classes.appBarLight, {
          [props.isMobile ? classes.mobileAppBarShift : classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {
            props.hasMenu ?
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon fontSize='large' />
              </IconButton>
              :
              <div></div>
          }
          <a href='/'>
            <div className='body logo-padding'>
              <img src={props.isDark ? darkLogo : lightLogo} alt="Tiffany Jaya's logo" className='logo-img' />
              {
                props.isDark ?
                  <p className='logo logo-txt-padding'>Tiffany Jaya<span className='pink-txt'>.</span></p>
                  :
                  <p className='logo logo-txt-padding navy-txt'>Tiffany Jaya<span className='pink-txt'>.</span></p>
              }

            </div>
          </a>
          {
            props.isDark ?
              <div className='nav-links'>
                <a className='dark-link nav-link' href='https://drive.google.com/file/d/1-BpwsFZDPPhLGcPz33UKPHXRO0QU68qB/view?usp=sharing' target='_blank'>Resume</a>
                <a className='dark-link nav-link' href='/'>Projects</a>
                <a className='dark-link nav-link' href='/about'>About</a>
                <a className='dark-link nav-link' href='https://tiffanyjaya.typeform.com/to/R6ZB50' target='_blank'>Contact</a>
              </div>
              :
              <div className='nav-links'>
                <a className='link nav-link' href='https://drive.google.com/file/d/1-BpwsFZDPPhLGcPz33UKPHXRO0QU68qB/view?usp=sharing' target='_blank'>Resume</a>
                <a className='link nav-link' href='/'>Projects</a>
                <a className='link nav-link' href='/about'>About</a>
                <a className='link nav-link' href='https://tiffanyjaya.typeform.com/to/R6ZB50' target='_blank'>Contact</a>
              </div>

          }

        </Toolbar>
      </AppBar>
      <Drawer
        className={props.isMobile ? classes.mobileDrawer : classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: props.isMobile ? classes.mobileDrawerPaper : classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        {props.sidenav}
      </Drawer>
      <main
        className={clsx(props.isMobile ? classes.mobileContent : classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <div className='body'>
          {children}
        </div>
      </main>
    </div >
  );
}

import React, { Component } from 'react';
/* CSS */
import '../../assets/css/Global.css';
import './CallToAction.css';

export default class CTAContact extends Component {
  render() {
    return (
      <div className='cta center-img-hv'>
        Let's build something <span className='yellow-txt'>great</span> together
        <div className='mt-10'>
          <a className='gradient-button gradient-button-blue cta-button' href='https://tiffanyjaya.typeform.com/to/R6ZB50'>Contact Me</a>
        </div>
      </div>
    );
  }
}
import React, { Component } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
/* CSS */
import '../../assets/css/Global.css';
import './Testimonial.css';

export default class Testimonial extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div className='testimonial-container'>
            <div className='testimonial'>
              <div className='testimonial-content rounded-corners'>
                <div className='testimonial-icon'>
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <p className='description'>
                  <div>
                    Working with Tiffany was a rare opportunity for Fractl
                    and a wonderful experience for me as her manager.
                    Tiffany is a driven, highly-motivated data visualization
                    engineer who went well beyond the base expectations of
                    her projects to deliver nuanced, well-thought out visualizations.
                    I was especially impressed by her ability to take feedback
                    and make changes in the visual and analytical direction of
                    a project while still delivering on time and with clear,
                    concise code that could easily be implemented by another
                    engineer.
                  </div>
                  <div className='mt-30'>
                    Tiffany’s data science skills and insights not only brought
                    value to our business, but also the end user who interacted
                    with her final product. I highly recommend Tiffany as a data
                    scientist and a colleague and would be thrilled to hire her
                    again if the opportunity arose.
                  </div>
                </p>
              </div>
              <h3 className='title'>Matt Gillespie</h3>
              <span className='post'>Head of Data Science @ Fractl</span>
            </div>
          </div>
          <div className='testimonial-container'>
            <div className='testimonial'>
              <div className='testimonial-content rounded-corners'>
                <div className='testimonial-icon'>
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <p className='description'>
                  <div>
                    It was fantastic working with Tiffany during my time in the
                    Management for Technology Innovation program. At every milestone,
                    I could count on her to consistently deliver high-quality work.
                    Despite having to pivot our product four times in a semester,
                    Tiffany maintained focus on the customer experience and a commitment
                    to total product quality. When working on a product, she balances
                    the macro perspective of the long term strategic vision with the
                    micro detail from her time interviewing individual users. Her unique
                    combination of technical know-how and design experience makes her a
                    valuable addition to any team, and Tiffany earns my highest
                    recommendations.
                  </div>
                </p>
              </div>
              <h3 className='title'>George Gillette</h3>
              <span className='post'>Logistic Analyst @ Amazon</span>
              <span className='post'>Work together as startup founders building a restaurant management system</span>
            </div>
          </div>
          <div className='testimonial-container'>
            <div className='testimonial'>
              <div className='testimonial-content rounded-corners'>
                <div className='testimonial-icon'>
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <p className='description'>
                  <div>
                    Tiffany is an incredible asset to our team. Without her,
                    our project would not go as far as it did. She was
                    exceptional in taking project requirements and churning
                    them out into user experiences that specifically cater
                    to our target audience. I especially appreciate that she
                    listens to what our key constituents needed and implements
                    them into our final prototype. Her multifaceted background
                    makes her a valuable key player since she not only helps
                    design what our final platform would look like but also
                    builds the entire infrastructure so that our idea comes
                    into fruition. She is one of the most responsible and
                    dependable person I have worked with. I recommend Tiffany
                    without hesitation and am excited to see where her career
                    adventure will take her next.
                  </div>
                </p>
              </div>
              <h3 className='title'>Alice Lam</h3>
              <span className='post'>Quantitative Research @ Millenium</span>
              <span className='post'>Work together on Pyxis, a visual stock screener</span>
            </div>
          </div>
          <div className='testimonial-container'>
            <div className='testimonial'>
              <div className='testimonial-content rounded-corners'>
                <div className='testimonial-icon'>
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
                <p className='description'>
                  <div>
                    Tiffany is an incredible asset to our team. Without her,
                    our project would not go as far as it did. She was
                    exceptional in taking project requirements and churning
                    them out into user experiences that specifically cater
                    to our target audience. I especially appreciate that she
                    listens to what our key constituents needed and implements
                    them into our final prototype. Her multifaceted background
                    makes her a valuable key player since she not only helps
                    design what our final platform would look like but also
                    builds the entire infrastructure so that our idea comes
                    into fruition. She is one of the most responsible and
                    dependable person I have worked with. I recommend Tiffany
                    without hesitation and am excited to see where her career
                    adventure will take her next.
                  </div>
                </p>
              </div>
              <h3 className='title'>Beau Kramer</h3>
              <span className='post'>Quantitative Analyst in Research & Development @ Solovis</span>
              <span className='post'>Work together on Pyxis, a visual stock screener</span>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}


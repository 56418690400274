import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '18px',
    lineHeight: '1.58',
    fontWeight: '400',
    color: '#111F3B',
  },
  heading: {
    fontSize: '18px',
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#111F3B',
  },
  fullHeading: {
    fontSize: '18px',
    flexBasis: '100%',
    flexShrink: 0,
    color: '#111F3B',
  },
  secondaryHeading: {
    fontSize: '18px',
    color: '#BFC3CB',
  },
}));

export default function Accordion(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel defaultExpanded={props.defaultExpanded}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          {
            props.secondaryHeading
              ?
              <div className={classes.heading}>{props.heading}</div>
              :
              <div className={classes.fullHeading}>{props.heading}</div>
          }
          <div className={classes.secondaryHeading}>{props.secondaryHeading}</div>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {props.details}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div >
  );
}
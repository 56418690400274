import React from 'react';
import './LockScreen.css';

class LockScreen extends React.Component {

  render() {
    return (
      <div className='lock-screen'>
        <div className='modal'>
          <b>Enter password:</b>

        </div>
      </div>
    );
  }
}

export default LockScreen;
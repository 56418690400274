import React from 'react';
import PersistentDrawerLeft from '../../../components/navbar/PersistentDrawerLeft';
import BottomNavbar from '../../../components/navbar/BottomNavbar';
import Footer from '../../../components/footer/Footer';
import { Row, Col } from 'react-flexbox-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
/* Image Zoom + Animation */
import ImageZoom from 'react-medium-image-zoom';
import Fade from 'react-reveal/Fade';
/* Carousel */
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Image
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
/* Sidenav */
import AnchorLink from 'react-anchor-link-smooth-scroll';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ProblemIcon from '@material-ui/icons/ReportRounded';
import UserIcon from '@material-ui/icons/FaceRounded';
import FeaturesIcon from '@material-ui/icons/AssignmentRounded';
import ProcessIcon from '@material-ui/icons/BuildRounded';
import FinalSolutionIcon from '@material-ui/icons/ImportantDevicesRounded';
import ImpactIcon from '@material-ui/icons/SentimentVerySatisfiedRounded';
/* CSS */
import './index.css';
import '../CaseStudy.css';
import '../../../assets/css/Global.css';
/* Images */
import Example1Img from './images/example-stock-screener-1.png';
import Example2Img from './images/example-stock-screener-2.png';
import Example3Img from './images/example-stock-screener-3.png';
import AvgWorkerImg from './images/average-worker.png';
import PortfolioManagerImg from './images/portfolio-manager.png';
import PainPointImg from './images/pain-point.png';
import WorseIdeaImg from './images/worse-idea.png';
import BrainstormPrototype from './images/pyxis-brainstorm.png';
import FirstVersionGif from './images/pyxis-first-version.gif';
import FirstVersion from './images/v1-design.png';
import SecondVersion from './images/v2-design.png';
import FinalVersion from './images/final-design.png';
import StyleGuide from './images/style-guide.png';
import Feature1 from './images/feature1.gif';
import Feature2 from './images/feature2.gif';
import Feature3 from './images/feature3.gif';
import Feature4 from './images/feature4.gif';
import Step1 from './images/1.png';
import Step2 from './images/2.gif';
import Step3 from './images/3.gif';
import Step4 from './images/4.gif';

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: '#04B6DB',
  },
  tooltip: {
    backgroundColor: '#04B6DB',
    fontSize: '18px',
    fontFamily: 'Montserrat, sans-serif',
    padding: '15px 30px',
  },
}));


function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}


class PyxisPage extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {

    const { width } = this.state;
    const isMobile = width <= 480;

    var sidenav =
      <List className='sidenav'>
        <AnchorLink offset={200} href='#problem' className='body link'>
          <ListItem button key={'problem'} >
            <ListItemIcon><ProblemIcon className='icon' /></ListItemIcon>
            <div>Problem</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={200} href='#user' className='body link'>
          <ListItem button key={'user'} >
            <ListItemIcon ><UserIcon className='icon' /></ListItemIcon>
            <div>User</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={() => isMobile ? 0 : 200} href='#process-1' className='body link'>
          <ListItem button key={'process'}>
            <ListItemIcon ><ProcessIcon className='icon' /></ListItemIcon>
            <div>Process</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={() => isMobile ? 0 : 200} href='#features' className='body link'>
          <ListItem button key={'features'}>
            <ListItemIcon ><FeaturesIcon className='icon' /></ListItemIcon>
            <div>Features</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={() => isMobile ? 0 : 200} href='#final-solution' className='body link'>
          <ListItem button key={'final-solution'}>
            <ListItemIcon ><FinalSolutionIcon className='icon' /></ListItemIcon>
            <div>Final Solution</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={() => isMobile ? 0 : 200} href='#impact' className='body link'>
          <ListItem button key={'final-solution'}>
            <ListItemIcon ><ImpactIcon className='icon' /></ListItemIcon>
            <div>Impact</div>
          </ListItem>
        </AnchorLink>
      </List>;

    const mobileSideNav =
      <List className='sidenav'>
        <AnchorLink offset={() => isMobile ? 350 : 200} href='#problem' className='body link'>
          <ListItem button key={'problem'} >
            <div>Problem</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={isMobile ? 200 : 200} href='#user' className='body link'>
          <ListItem button key={'user'} >
            <div>User</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={isMobile ? 200 : 200} href='#features' className='body link'>
          <ListItem button key={'features'}>
            <div>Findings</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={isMobile ? 200 : 200} href='#process-1' className='body link'>
          <ListItem button key={'process'}>
            <div>Process</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={isMobile ? 200 : 200} href='#final-solution' className='body link'>
          <ListItem button key={'final-solution'}>
            <div>Final Solution</div>
          </ListItem>
        </AnchorLink>
        <AnchorLink offset={isMobile ? 200 : 200} href='#impact' className='body link'>
          <ListItem button key={'final-solution'}>
            <div>Impact</div>
          </ListItem>
        </AnchorLink>
      </List>;

    sidenav = isMobile ? mobileSideNav : sidenav;

    return (
      <PersistentDrawerLeft sidenav={sidenav} isMobile={isMobile} hasMenu={true} isDark={true}>
        <Col xs={0} sm={0} md={1} lg={1} className='zero-buffer no-show-mobile'></Col>
        <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
          <div className='section'></div>
          <div className='section section-padding'>
            <div className='project-title'>Pyxis</div>
            <div className='project-objective'>
              Discover companies to invest in half the time
            </div>
            <div className='line'></div>
            <div className='project-overview'>
              <Row>
                <Col xs={4} sm={4} md={3} lg={3} className='no-padding'>
                  <b>My Role</b>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={7} sm={7} md={8} lg={8}>
                  Product Designer<br />
                  Full Stack Engineer<br />
                  Data Scientist
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4} md={3} lg={3} className='no-padding'>
                  <b>Tools Used</b>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={7} sm={7} md={8} lg={8}>
                  Sketch<br />
                  D3, React, Django, Postgres
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4} md={3} lg={3} className='no-padding'>
                  <b>Platforms</b>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={7} sm={7} md={8} lg={8}>
                  Web
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4} md={3} lg={3} className='no-padding'>
                  <b>Collaborated With</b>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={7} sm={7} md={8} lg={8}>
                  <a href='https://www.linkedin.com/in/alicelamds/' target='_blank'>Alice Lam</a> (equity portfolio manager)<br />
                  <a href='https://www.linkedin.com/in/beau-kramer-b3204521/' target='_blank'>Beau Kramer</a> (quantitative analyst)<br />
                  <a href='https://www.linkedin.com/in/cpapadimitriou1/' target='_blank'>Christina Papadimitriou</a> (machine learning engineer)
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4} md={3} lg={3} className='no-padding'>
                  <b>Duration</b>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}></Col>
                <Col xs={7} sm={7} md={8} lg={8}>
                  Jan - May 2019
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} className='no-padding project-context'>
                  <div className='paragraph'>
                    <b>This is my Master's Capstone Project at UC Berkeley</b>
                  </div>
                  <div className='paragraph'>
                    I designed the navigation of a
                    visual <BootstrapTooltip title="Stock screener is a software designed to search for next companies to invest in using criteria provided by portfolio managers."><u>stock screeners</u></BootstrapTooltip> so
                    that <BootstrapTooltip title="Portfolio managers invest in companies that will earn their clients more money in the future."><u>portfolio managers</u></BootstrapTooltip> can
                    <ul>
                      <li>discover the next companies to invest in half the time</li>
                      <li>explore data without being overwhelmed</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div><div className='section section-padding'>
            <section id={'problem'}>
              <div className='header'>Problem</div>
              <div className='paragraph'>
                Finding the next company to invest in is hard.
              </div>
              <div className='paragraph'>
                To figure out the reason why, I interviewed
                three <BootstrapTooltip title="Portfolio managers invest in companies that will earn their clients more money in the future."><u>portfolio managers</u></BootstrapTooltip>.
                They all agreed that the problem lies in ...
              </div>
              <div className='paragraph title pink-txt'>
                "Too much data, not enough time"
              </div>
              <div className='paragraph'>
                To better understand what they meant by this, I did a competitive analysis
                on <BootstrapTooltip title="Stock screener is a software designed to search for next companies to invest in using criteria provided by portfolio managers."><u>stock screeners</u></BootstrapTooltip>,
                the tools my users used to find the next company to invest in.
              </div>
              <div className='paragraph img-container'>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className='left-panel-img-1b'>
                    <ImageZoom
                      image={{
                        src: Example2Img,
                        alt: 'stock screener example',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: Example2Img,
                        alt: 'stock screener example',
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className='no-show-mobile'>
                    <Row className='right-panel-img-1b'>
                      <ImageZoom
                        image={{
                          src: Example1Img,
                          alt: 'stock screener example',
                          className: 'img shadow'
                        }}
                        zoomImage={{
                          src: Example1Img,
                          alt: 'stock screener example',
                        }}
                      />
                    </Row>
                    <Row className='right-panel-img-2'>
                      <ImageZoom
                        image={{
                          src: Example3Img,
                          alt: 'stock screener example',
                          className: 'img shadow'
                        }}
                        zoomImage={{
                          src: Example3Img,
                          alt: 'stock screener example',
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <div className='caption no-show-mobile'>
                  Examples of different stock screeners
                </div>
                <div className='caption no-show-desktop'>
                  An example of a stock screener
                </div>
              </div>
              <div className='paragraph'>
                I learned that the current designs were ineffective because
                <ol>
                  <li>They are text heavy</li>
                  <li>It is difficult to compare more than two companies</li>
                  <li>It does not give insights into the industries the companies are in</li>
                  <li>Portfolio managers are expected to know what they are looking for</li>
                </ol>
              </div>
            </section>
          </div>
          <div className='section section-padding '>
            <section id={'user'}>
              <div className='header'>Why is this an important problem to solve</div>
              <div className='first-paragraph'>
                Even after the interview, I still did not understand why this is an important problem to solve. So I conducted an ethnographic interview and spent a good part of my day observing what a portfolio manager does.
              </div>
              <div className='paragraph'>
                <BootstrapTooltip title="Portfolio managers invest in companies that will earn their clients more money in the future." className='pink-txt'><u>Portfolio managers</u></BootstrapTooltip> spend at least 30 hours learning about a company before making the decision to buy its stock.
              </div>
              <div className='paragraph'>
                This means they don't have time to look at all the companies.
              </div>
              <div className='subheader'>Why this is a huge pain point</div>

              <div className='z-img-txt'>
                <Fade bottom duration={3000}>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <img src={AvgWorkerImg} alt='average workers' className='img shadow' />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className='z-txt-right'>
                      <div className='presubheader'>Average Worker</div>
                      <b>Work Hours in a Year</b>
                      <div className='paragraph'>
                        <div className='work-hrs-container'>
                          <div className='calc-work-hrs'>
                            <div className='fraction-top center-txt' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              40 hours
                            </div>
                            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>x</div>
                            <div className='fraction-top center-txt' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              52 weeks
                            </div>
                          </div>

                          <div className='calc-work-hrs'>
                            <div className='fraction-bottom center-txt' style={{ paddingLeft: '23px', paddingRight: '23px' }}>
                              1 week
                            </div>
                            <div style={{ paddingLeft: '30px', paddingRight: '30px' }}></div>
                            <div className='fraction-bottom center-txt' style={{ paddingLeft: '23px', paddingRight: '23px' }}>
                              1 year
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='first-paragraph'>
                        = 2080 hours/year
                      </div>
                    </Col>
                  </Row>
                </Fade>
              </div>
              <div className='paragraph z-img-txt'>
                <Fade bottom duration={5000}>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6} className='z-txt-left no-show-mobile'>
                      <span className='presubheader'>Portfolio Manager</span><br />
                      <b>Work Hours in a Year</b>

                      <div className='paragraph'>
                        <div className='work-hrs-container'>
                          <div className='calc-work-hrs'>
                            <div className='fraction-top center-txt' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                              30 hours
                            </div>
                            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>x</div>
                            <div className='fraction-top center-txt' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              100 companies
                            </div>
                          </div>

                          <div className='calc-work-hrs'>
                            <div className='fraction-bottom center-txt' style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                              1 company
                            </div>
                            <div style={{ paddingLeft: '30px', paddingRight: '30px' }}></div>
                            <div className='fraction-bottom center-txt' style={{ paddingLeft: '45px', paddingRight: '45px' }}>
                              1 year
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='first-paragraph'>
                        = 3000 hours/year<br />
                        <b className='pink-txt'>&nbsp;&ensp;work extra 18 hours/week</b>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <img src={PortfolioManagerImg} alt='portfolio managers' className='img shadow' />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className='z-txt-left no-show-desktop'>
                      <span className='presubheader'>Portfolio Manager</span><br />
                      <b>Work Hours in a Year</b>

                      <div className='paragraph'>
                        <div className='work-hrs-container'>
                          <div className='calc-work-hrs'>
                            <div className='fraction-top center-txt' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                              30 hours
                            </div>
                            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>x</div>
                            <div className='fraction-top center-txt' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              100 companies
                            </div>
                          </div>

                          <div className='calc-work-hrs'>
                            <div className='fraction-bottom center-txt' style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                              1 company
                            </div>
                            <div style={{ paddingLeft: '30px', paddingRight: '30px' }}></div>
                            <div className='fraction-bottom center-txt' style={{ paddingLeft: '45px', paddingRight: '45px' }}>
                              1 year
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='first-paragraph'>
                        = 3000 hours/year<br />
                        <b className='pink-txt'>&nbsp;&ensp;work extra 18 hours/week</b>
                      </div>
                    </Col>
                  </Row>
                </Fade>
              </div>
              <div className='z-img-txt'>
                <Fade bottom duration={7000}>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <img src={PainPointImg} alt='pain point of portfolio managers' className='img shadow' />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className='z-txt-right'>
                      <div className='paragraph'>
                        Even with working 18 extra hours/week, they can only look at 100 companies in a given year.
                      </div>
                      <div className='paragraph'>
                        They only have enough time to look into <span className='pink-txt'><b>less than 3%</b></span> of
                        all US stocks!
                      </div>
                      <div className='paragraph'>
                        This is why portfolio managers need a faster way
                        to discover companies.
                      </div>
                    </Col>
                  </Row>
                </Fade>
              </div>
            </section>
          </div>
          <div className='section section-padding '>
            <section id={'process'}>
              <div className='header'>Proposed solution</div>
              <div className='first-paragraph'>
                Based on my research, we decided to build a <span className='pink-txt'><b>visual stock screener</b></span>.
              </div>
              <div className='paragraph'>
                It can solve all the problems we found in the current
                stock screener designs.
                {/*
                <ul className='checkmark'>
                  <li className='checkmark'>They are text heavy</li>
                  <li className='checkmark'>It is difficult to compare more than two companies</li>
                  <li className='checkmark'>It does not give insights into the industries the companies are in</li>
                <li className='checkmark'>Portfolio managers are expected to know what they are looking for</li>
                </ul>
                */}
              </div>
              <div className='top-3em'>
                The challenge we faced while building this is that:
                <ol>
                  <li>Portfolio managers are new to the idea of a visual stock screener</li>
                  <li>They have little time to spare for user testing</li>
                </ol>
              </div>
            </section>
          </div>
          <div className='section section-padding '>
            <section id={'process-1'}>
              <div className='header'>Process</div>
              <div className='first-paragraph'>
                I decided to first design a visual stock screener and then asked
                portfolio managers what features are missing.
              </div>
              <div className='paragraph'>
                This way we do not have to deal with contradictory requests of
                what they want to see in our product, simply because our users
                do not have time for a back and forth Q&A.
              </div>
              <div className='subheader first-paragraph'>
                Prototype together as a team to come up with the first design
              </div>
              <div className='paragraph img-container'>
                <Row className='no-show-mobile' style={{ paddingBottom: '1em' }}>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div
                      style={{ paddingRight: '1em' }}
                    >
                      <b>Worst possible idea</b>
                      <div className='paragraph'>
                        To get my team’s creative juices flowing, I asked them to
                        come up with the worst possible idea: overwhelming the user
                        by compiling all information into one dashboard.
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div>
                      <b>The design that we like</b>
                    </div>

                    <div className='paragraph'
                      style={{ paddingRight: '1em' }}
                    >
                      We liked the idea of clustering companies with similar financial characteristics because the users could then easily discover new
                      companies similar to ones they knew were successful.
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <ImageZoom
                      image={{
                        src: WorseIdeaImg,
                        alt: 'worse idea',
                        className: 'img shadow d90m100'
                      }}
                      zoomImage={{
                        src: WorseIdeaImg,
                        alt: 'worse idea',
                      }}
                    />
                    <div className='caption-left no-show-desktop' style={{ paddingBottom: '2em' }}>
                      <b>Worst possible idea</b>
                      <div className='paragraph'>
                        To get my team’s creative juices flowing, I asked them to
                        come up with the worst possible idea: overwhelming the user
                        by compiling all information into one dashboard
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className='img-separation'>
                    <ImageZoom
                      image={{
                        src: BrainstormPrototype,
                        alt: 'prototype together',
                        className: 'img shadow d90m100',
                      }}
                      zoomImage={{
                        src: BrainstormPrototype,
                        alt: 'prototype together',
                      }}
                    />
                    <div className='caption no-show-mobile' style={{ width: '90%' }}>
                      We brainstormed on pen and paper <br />
                      and tested its feasibility on Tableau
                    </div>

                    <div className='caption-left no-show-desktop' style={{ paddingBottom: '2em' }}>
                      <b>The design that we like</b>
                      <div className='paragraph'>
                        We liked the idea of clustering companies with similar financial characteristics because the users could then easily discover new
                        companies similar to ones they knew were successful.
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className='top-2em'>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className='no-show-mobile' style={{ paddingBottom: '1em' }}>
                      <b>Refine design in Sketch</b>
                      <div className='paragraph'>
                        I further refined it in Sketch to incorporate features that
                        the team wanted but was not available in Tableau.
                      </div>
                    </div>
                    <ImageZoom
                      image={{
                        src: FirstVersionGif,
                        alt: 'first version',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: FirstVersionGif,
                        alt: 'first version',
                      }}
                    />
                    <div className='caption-left no-show-desktop'>
                      <b>Refine design in Sketch</b>
                      <div className='paragraph'>
                        Once we verified that we could implement our own design, I further refined it in Sketch to incorporate features that will help our users not be overwhelmed with data:
                        <ul>
                          <li>List companies associated to a cluster</li>
                          <li>Ability to click on a cluster and see its associated companies</li>
                          <li>Ability to search for a company</li>
                          <li>Ability to see data over time, not all at once</li>
                        </ul>
                      </div>
                    </div>
                    <div className='caption no-show-mobile'>
                      Companies that are financially similar are closer together on the graph
                    </div>


                  </Col>

                </Row>


              </div>

            </section>
          </div>
          <div className='section section-padding '>
            <section id={'get-features'}>
              <div className='subheader'>Figure out what features are missing</div>
              <div className='first-paragraph'>
                By presenting this design to our users, it was easier for them to
                point out what features were missing than it would have been for them
                to think about what they need off the top of their head.
              </div>
              <div className='top-3em'>
                The features they needed from our product were:
              </div>
              <div className='paragraph'>
                <ol>
                  <li>Find companies with similar financial characteristics</li>
                  <li>Compare companies by their financial characteristics</li>
                  <li>Compare the diversity between multiple industries</li>
                  <li>Search for companies that do surprisingly well and surprisingly poor</li>
                </ol>
              </div>
            </section>
          </div>
          <div className='section section-padding '>
            <section id={'navigation-1'}>
              <div className='subheader'>How navigation design can accommodate these features</div>
              <div className='first-paragraph'>
                I realized it was not easy to incorporate all these features without a well thought out navigation. A good one enables users to achieve their goals. A bad one forces users to abandon the product altogether.
              </div>
              <div className='paragraph pink-txt'>
                <b>
                  In our case, a good navigation helps portfolio managers
                  refine their search from 3500+ stocks to a number that is maneagable.
                </b>
              </div>
              <div className='paragraph img-container'>
                <CarouselProvider
                  currentSlide={0}
                  visibleSlides={1}
                  totalSlides={4}
                  naturalSlideWidth={800}
                  naturalSlideHeight={450}
                  step={1}
                  infinite={true}
                  isPlaying={false}
                  interval={5000}
                  hasMasterSpinner
                  lockOnWindowScroll
                  className='carousel-container'
                >
                  <ButtonBack className='carousel-left-container fa fa-arrow-circle-o-left'>	</ButtonBack>
                  <Slider className='carousel-center-container'>
                    <Slide index={0}>
                      <Image src={Step1}
                        alt='Step 1: see all 3500 stocks'
                      />
                    </Slide>
                    <Slide index={1} >
                      <ImageZoom
                        image={{
                          src: Step2,
                          alt: 'Step 2: refine by similar financial characteristics',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: Step2,
                          alt: 'Step 2: refine by similar financial characteristics',
                        }}
                      />
                    </Slide>
                    <Slide index={2}>
                      <ImageZoom
                        image={{
                          src: Step3,
                          alt: 'Step 3: refine by industries',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: Step3,
                          alt: 'Step 3: refine by industries',
                        }}
                      />
                    </Slide>
                    <Slide index={3}>
                      <ImageZoom
                        image={{
                          src: Step4,
                          alt: 'Step 4: refine by return on investment',
                          style: { width: '100%' }
                        }}
                        zoomImage={{
                          src: Step4,
                          alt: 'Step 4: refine by return on investment',
                        }}
                      />
                    </Slide>
                  </Slider>
                  <ButtonNext className='carousel-right-container fa fa-arrow-circle-o-right'></ButtonNext>
                  <DotGroup
                    className='carousel-dot-group'
                  />
                </CarouselProvider>
                <div className='caption'>
                  <b>Refine search from 3500+ stocks to just 1 stock</b><br /><br />
                  Step 1: Choose a cluster to find companies with similar financial characteristics<br />
                  Step 2: Choose the industries to invest in<br />
                  Step 3: Choose companies whose return on investment do surprisingly
                  well
                </div>
              </div>
            </section>
          </div>


          <div className='section section-padding '>
            <section id={'quote'}>
              <div className='pink-txt center-txt'>
                <b className='subheader'><i>"I wish I had this when I first started my job"</i></b><br />
                - Samantha Y, a portfolio manager in Hong Kong
              </div>
            </section>
          </div>
          <div className='section section-padding '>
            <section id={'features'}>
              <span className='preheader'>Putting it all together</span><br />

              <div className='header'>What Pyxis can do for you</div>
              <div className='paragraph img-container'>
                <iframe allowfullscreen
                  style={{ width: '100%', height: '40vh' }}
                  src='https://www.youtube.com/embed/gSNidjqUipY'
                />
              </div>
              <div className='z-img-txt'>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <ImageZoom
                      image={{
                        src: Feature1,
                        alt: 'how pyxis addresses feature 1',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: Feature1,
                        alt: 'how pyxis addresses feature 1',
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className='z-txt-right'>
                    <b>Find companies with similar financial characteristics</b>
                    <div className='paragraph'>
                      Find companies that are financially similar to Tesla
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='paragraph z-img-txt'>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className='z-txt-left no-show-mobile'>
                    <b>Compare companies by their financial characteristics</b>
                    <div className='paragraph'>
                      Figure out why Tesla is not similar to Apple or Google
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <ImageZoom
                      image={{
                        src: Feature2,
                        alt: 'how pyxis addresses feature 2',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: Feature2,
                        alt: 'how pyxis addresses feature 2',
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className='z-txt-left no-show-desktop'>
                    <b>Compare companies by their financial characteristics</b>
                    <div className='paragraph'>
                      Figure out why Tesla is not similar to Apple or Google
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='paragraph z-img-txt'>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <ImageZoom
                      image={{
                        src: Feature3,
                        alt: 'how pyxis addresses feature 3',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: Feature3,
                        alt: 'how pyxis addresses feature 3',
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className='z-txt-right'>
                    <b>Compare the diversity between multiple industries</b>
                    <div className='paragraph'>
                      Figure out which industry is more diverse: Energy vs Utilities
                    </div>
                    <div className='paragraph'>
                      The energy industry is more diverse because
                      companies are not as financially similar (more spread out).
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='paragraph z-img-txt'>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className='z-txt-left no-show-mobile'>
                    <b>Search for companies that do surprisingly well and surprisingly poor</b>
                    <div className='paragraph'>
                      See what companies in the Energy industry that are doing surprisingly well and surpisingly poor
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <ImageZoom
                      image={{
                        src: Feature4,
                        alt: 'how pyxis addresses feature 4',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: Feature4,
                        alt: 'how pyxis addresses feature 4',
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className='z-txt-left no-show-desktop'>
                    <b>Search for companies that do surprisingly well and surprisingly poor</b>
                    <div className='paragraph'>
                      See what companies in the Energy industry that are doing surprisingly well and surpisingly poor
                    </div>
                  </Col>
                </Row>
              </div>
            </section>
          </div>
          <div className='section section-padding '>
            <section id={'final-solution'}>

              <div className='header'>Final Solution</div>
              <div className='first-paragraph'>
                Since onboarding was not part of the deliverable, we tried to make the final design as intuitive as possible by separating each section into its own container with clear headers.
              </div>
              <div className='paragraph img-container'>
                <ImageZoom
                  image={{
                    src: FinalVersion,
                    alt: 'final version',
                    className: 'img shadow'
                  }}
                  zoomImage={{
                    src: FinalVersion,
                    alt: 'final version',
                  }}
                />
                <div className='paragraph'>
                  <a href='http://tiffapedia-pyxis.herokuapp.com/'
                    style={{ textDecoration: 'none' }}
                    target='_blank'
                  >
                    <div
                      className='gradient-button gradient-button-circular gradient-button-purple'
                      style={{ margin: '0 auto' }}
                    >
                      View Prototype
                    </div>
                  </a>
                </div>
              </div>


              <div className='top-2em header'>Iteration</div>
              <div className='paragraph'>
                <Row className='no-show-mobile'>
                  <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='caption caption-padding'>
                      Version 1
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='caption caption-padding'>
                      Version 3
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='caption caption-padding'>
                      Version 7
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={3}>
                    <div className='caption caption-padding'>
                      Version 8<br />
                      Final Version
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={3} lg={3}>
                    <ImageZoom
                      image={{
                        src: BrainstormPrototype,
                        alt: 'Version 1',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: BrainstormPrototype,
                        alt: 'Version 1',
                      }}
                    />
                    <div className='caption caption-padding no-show-desktop'>
                      Version 1
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={3} className='img-separation'>
                    <ImageZoom
                      image={{
                        src: FirstVersion,
                        alt: 'Version 3',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: FirstVersion,
                        alt: 'Version 3',
                      }}
                    />
                    <div className='caption caption-padding no-show-desktop'>
                      Version 3
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={3} className='img-separation'>
                    <ImageZoom
                      image={{
                        src: SecondVersion,
                        alt: 'Version 7',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: SecondVersion,
                        alt: 'Version 7',
                      }}
                    />
                    <div className='caption caption-padding no-show-desktop'>
                      Version 7
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={3} className='img-separation'>
                    <ImageZoom
                      image={{
                        src: FinalVersion,
                        alt: 'Final Version',
                        className: 'img shadow'
                      }}
                      zoomImage={{
                        src: FinalVersion,
                        alt: 'Final Version',
                      }}
                    />
                    <div className='caption caption-padding no-show-desktop'>
                      Version 8<br />
                      Final Version
                    </div>
                  </Col>
                </Row>
              </div>



            </section>
          </div>
          <div className='section section-padding '>
            <section id={'style-guideline'}>
              <div className='header'>Style Guideline</div>
              <div className='first-paragraph'>
                I designed the logo to reflect our team’s name, Pyxis. Pyxis is a compass constellation. I embedded a compass within a magnifying glass to symbolize how our product can guide our users to the right companies to invest in next.
              </div>
              <div className='paragraph'>
                I also chose the color theme that evokes the feeling of the night sky.
              </div>
              <div className='paragraph img-container'>
                <ImageZoom
                  image={{
                    src: StyleGuide,
                    alt: 'style guideline',
                    className: 'img shadow'
                  }}
                  zoomImage={{
                    src: StyleGuide,
                    alt: 'style guideline',
                  }}
                />
              </div>
            </section>
          </div>

          <div className='section section-padding'>
            <section id={'impact'}>
              <div className='header'>Impact</div>
              <div className='paragraph'>
                <div className='title pink-txt'>reduce job to 1 min</div>
                We achieved what we sought out to do, which was to reduce the average time spent in searching for a company without a stock screener from 30 hours to 1 minute.
              </div>
              <div className='paragraph'>
                It is a little harder to assess how well we do in comparison to other stock screeners because most stock screeners have a learning curve before users can use it.
              </div>
              <div className='first-paragraph'>
                <div className='title pink-txt'>+18% in investment accuracy</div>
                Compared to how portfolio managers performed at 21% accuracy, Pyxis was able to accurately group stocks and predict how well the stocks perform one year in the future with 39% accuracy.
              </div>
            </section>
          </div>
          <div className='section section-padding'>
            <section id={'lessons-learned'}>
              <div className='header'>Lessons Learned</div>
              <div className='subheader'>
                Onboarding experience is as equally important as the product itself
              </div>
              <div className='paragraph'>
                As intuitive as I tried to make the product, the concept of a visual stock screener was still foreign to portfolio managers, so some learning curve still existing was inevitable. I realized that without an onboarding experience to help users understand how to use the product, no matter what it promises to deliver, users will not use it. If I had more time, I would include an onboarding experience.
              </div>
              <div className='subheader'>
                A complex problem like this one requires more in-depth research
              </div>
              <div className='paragraph'>
                Even after taking a Portfolio Management class and spending an entire day with a portfolio managers, there was a lot of terminology I still did not understand. Not understanding these concepts made it hard to translate the user’s needs to design. For example, I was not able to communicate what “Return on Assets”, “EBIT Profit Margin” and “Total Assets Growth” together mean to portfolio managers. I just had to list them as is with the assumption that they would know what they mean.
              </div>
              <div className='subheader'>
                Testing on the average users is a good alternative to test basic functionalities if the actual users are not available
              </div>
              <div className='paragraph'>
                Our product is trying to solve the problem of portfolio managers having limited time. It’s not a surprise then that our users have limited time to give to us for user testing. When trying to test out if the navigation made sense, I ended up testing it with eight users at my local Peet’s coffee shop. Their feedback was similar to portfolio managers’. Of course this only works with basic functionalities and not one where the actual users’ work experience is necessary.
              </div>
            </section>
          </div>

        </Col >
        <div className='padding-for-footer' />
        <div className='bottom-nav'>
          <BottomNavbar />
        </div>
      </PersistentDrawerLeft >
    )
  }
}

export default PyxisPage;
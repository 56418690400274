import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

export default function AboutMeAccordion() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          My Skills
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <div className='paragraph'>
              My experience as a <b>product designer</b>, <b>data scientist</b>, 
              and <b>software engineer</b> allows me to draw the best elements of each and
              deliver you exceptional work.
            </div>
              <div className='subheader'>What I can do</div>
              <ol>
                <li>User research, market research, competitive analysis</li>
                <li>User interviews</li>
                <li>Feature definition: job stories</li>
                <li>Information architecture: user flow, task flow, functionality map</li>
                <li>Prototyping: low/mid/high fidelity wireframes</li>
                <li>Responsive design: design for mobile, tablet, desktop</li>
                <li>Interactive design: design for movement in digital products</li>
                <li>Usability testing</li>
                <li>Brand guidelines</li>
                <li>Extract insight from data, including writing a killer EDA
                  (exploratory data analysis) report
                </li>
                <li>Data visualization design + implementation (frontend, backend, database)</li>
              </ol>
              <div className=' subheader'>Protoyping</div>
              <ol>
                <li>Sketch</li>
                <li>Figma</li>
                <li>Invision</li>
                <li>Marvel</li>
              </ol>
              <div className=' subheader'>Languages</div>
              <ol>
                <li>Python + Django framework</li>
                <li>R</li>
                <li>SQL</li>
                <li>Javascript + React framework</li>
                <li>HTML</li>
                <li>CSS</li>
              </ol>
              <div className='subheader'>Libraries</div>
              <ol>
                <li>Numpy</li>
                <li>Scipy</li>
                <li>Pandas</li>
                <li>D3</li>
                <li>Matplotlib</li>
                <li>Seaborn</li>
                <li>Plotly</li>
                <li>Scikit-learn</li>
              </ol>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          My Degrees
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ol>
            <li>UX Academy certificate from DesignLab</li>
            <li>Masters in Data Science from UC Berkeley, GPA: 4.0</li>
            <li>Bachelors in Computer Science from UC San Diego, GPA: 3.3</li>
          </ol>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          My Design Principles
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <div className='paragraph'>
            I live and breathe by these values as a product designer.
            They are my motivational mantras.
            
            </div>
            <div className=' subheader'>Keep It Simple Silly</div>
            <div className='paragraph'>
              The bottom line is everyone is busy. I understand
              our need to get straight to the point. That means removing all visual
              clutters and simplifying the design so that our users can get the task
              done without distraction.
            </div>
              <div className=' subheader'>Test Early, Test Often</div>
              <div className='paragraph'>
                Testing reduces the risk of building the wrong product. If done early,
                we can find the problems that are easy and cheap to fix. If done often, we
                can chip at the problems until the product is close enough
                to perfect.
              </div>
              <div className=' subheader'>Give Me The Data</div>
              <div className='paragraph'>
              Qualitative data* is good. But having both qualitative and quantiative
              data is next level because more insight means better design.<br />
              <i>The result from user testing is qualitative data, i.e. understanding
                what triggers the stress response</i>
                </div>
            <div className='subheader'>We're All In This Together</div>
            <div className='paragraph'>
              People may not share the same background but we are all striving
              to do our best.
            </div>
            <div className='subheader'>Honesty Is The Best Policy</div>
            <div className='paragraph'>
              I am not afraid to be honest with you.
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel  expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          My Experiments
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            When I'm not working, I love to explore new ways of thinking.
            Here are a list of my recent discoveries (write up for each one is coming soon): <br />
              <ol>
                <li>My thoughts on Sketch, Figma, Adobe XD, Framer, Invision, Gravit</li>
                <li>Monthly Reddit's DataViz Battle</li>
              </ol>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          My Inspirations
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            Please feel free to share your favorite inspirations. I would love to hear it!
            <div className='subheader'>UX Design</div>
            <ol>
              <li><a href='https://ueno.co'>Ueno</a></li>
              <li><a href='https://metalab.co/'>Metalab</a></li>
              <li><a href='https://braveux.com/work'>Brave UX</a></li>
              <li><a href='https://www.strv.com/'>STRV</a></li>
              <li><a href='https://www.webbyawards.com/winners/2019/websites/features-design/best-user-experience/'>Webby Awards in UX</a></li>
            </ol>
            <div className='subheader'>Data Visualization</div>
            <ol>
              <li><a href='https://dataveyes.com/#!/en'>Dataveyes</a></li>
              <li><a href='https://www.visualcinnamon.com'>Visual Cinnamon</a></li>
            </ol>
            <div className='subheader'>Data Science</div>
            <ol>
              <li><a href='https://statquest.org/video-index/'>StatQuest</a></li>
              <li><a href='https://www.youtube.com/user/edurekaIN/featured'>Edureka</a></li>
            </ol>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div >
  );
}
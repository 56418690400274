import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ResumeIcon from '@material-ui/icons/MoveToInboxRounded';
import ContactIcon from '@material-ui/icons/MailRounded';
import AboutIcon from '@material-ui/icons/FaceRounded';
import ProjectsIcon from '@material-ui/icons/ImportantDevicesRounded';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function BottomNavbar() {
  const classes = useStyles();
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation value={value} onChange={handleChange} className={classes.root} showLabels>
      <BottomNavigationAction
        value="resume"
        label={<div className='navy-txt'>Resume</div>}
        icon={
          <a href='https://drive.google.com/file/d/1-BpwsFZDPPhLGcPz33UKPHXRO0QU68qB/view?usp=sharing'>
            <ResumeIcon className='navy-txt' />
          </a>
        }
      />
      <BottomNavigationAction
        value="resume"
        label={<div className='navy-txt'>Projects</div>}
        icon={
          <a href='/'>
            <ProjectsIcon className='navy-txt' />
          </a>
        }
      />
      <BottomNavigationAction
        value="about"
        label={<div className='navy-txt'>About</div>}
        icon={
          <a href='/about'>
            <AboutIcon className='navy-txt' />
          </a>
        }
      />
      <BottomNavigationAction
        value="contact"
        label={<div className='navy-txt'>Contact</div>}
        icon={
          <a href='https://tiffanyjaya.typeform.com/to/R6ZB50'>
            <ContactIcon className='navy-txt' />
          </a>
        }
      />
    </BottomNavigation>
  );
}
import React, { Component } from 'react';
import withImportantStyle from 'react-with-important-style';

/* CSS */
import '../../assets/css/Global.css';
import './Footer.css';
/* Images */
import logo from '../../assets/images/white-logo.png';

export default class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <div className='logo'>
          <img src={logo} alt='logo' className='logo-img' />
          <span className='logo-txt'>
            Tiffany Jaya<span className='pink-txt'>.</span>
          </span>
        </div>
        <br />
        Designed and coded by me <span class="pink-txt">&hearts;</span>
      </div>
    );
  }
}
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Navbar from '../../../components/navbar/Navbar';
import CTAContact from '../../../components/callToAction/CTAContact2';
import Footer from '../../../components/footer/Footer';
/* CSS */
import '../CaseStudy.css';
import '../../../assets/css/Global.css';
/* Images */

class EsePage extends React.Component {

  render() {
    return (
      <Navbar>
        <Grid fluid>
          <Row className='mt-260'>
            <Col lg={1}></Col>
            <Col lg={10} className='project-name'>Ese</Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={8} className='project-question mt-80'>
              How can we help<br />
              marketers expedite<br />
              their reporting process?
            </Col>
            <Col lg={3}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={10} className='project-type mt-50'>
              SaaS B2B
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={1} className='mt-100'>
              <div className='line'></div>
            </Col>
            <Col lg={11}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-35'>
              <b>Users</b>
            </Col>
            <Col lg={7} className='mt-35'>
              Marketers
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>Sector</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Marketing
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>My Role</b>
            </Col>
            <Col lg={7} className='mt-5'>
              Product designer
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={3} className='mt-5'>
              <b>What I Delivered</b>
            </Col>
            <Col lg={7} className='mt-5 mb-70'>
              Responsive website design
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={10}>
              <div className='mt-70'>
                After <a href='/projects/twitterrain'>Twitterrain</a>, I wanted to revisit the problem consistently brought up by marketers in the user surveys. Marketers spend most of their time creating reports. Ese is designed as a marketing tool that can:               </div>
              <ol className='mt-30'>
                <li>Extract insights from data without having knowledge of SQL</li>
                <li>Automate the creation of the reporting process once the settings are already set</li>
                <li>Share reports to cross functional teams to gain approval</li>
              </ol>
            </Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-70'>
              Challenge
            </Col>
            <Col lg={7} className='mt-70'>
              <ol>
                <li>Identify key areas to support marketers with managing numerous reports across different clients and different teams.</li>
                <li>Demystify the capabilities of SQL without having knowledge of SQL.</li>
                <li>Translate KPIs defined by marketers into understandable language for non-marketers approving the reports.</li>
                <li>Figuring out a design that can determine when it is best to explore the data or when it is time to put those findings into the report.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-50'>
              Approach
            </Col>
            <Col lg={7} className='mt-50'>
              <ol>
                <li>Took a marketing course to understand how marketers create reports.</li>
                <li>Performed competitive analysis to discover what products already addressed pain points and what products did not.</li>
                <li>Worked with marketers who can continually inject feedback with every design iteration.</li>
                <li>Formed design principles to guide user interface design and information architecture.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='navy-bg'>
            <Col lg={1}></Col>
            <Col lg={3} className='subheader mt-50'>
              Impact
            </Col>
            <Col lg={7} className='mt-50 mb-70'>
              <ol>
                <li>Increase completion rate from 5% to 83%.</li>
                <li>From a scale of 1 to 10 with 10 being most likely to share with other marketers, I have increased rate from 4 to 6 in the redesign.</li>
                <li>Learn the complexities of building a competitor tool against Google Data Studio.</li>
              </ol>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row>
            <Col lg={1}></Col>
            <Col lg={10}>
              <div className='mt-70 mb-70 center-txt'>
                <a
                  className='gradient-button gradient-button-pink case-study-button'
                  href='https://projects.invisionapp.com/share/JMTC0IYNSQH?#/screens/377677974_Home'
                  target='_blank' rel="noopener noreferrer"
                >
                  View Prototype
                </a>              </div>
              <div className='mt-70 mb-70 center-txt'>Detail writeup coming soon.</div>
            </Col>
            <Col lg={1}></Col>
          </Row>
          <Row className='mt-120 cta-bg'>
            <Col lg={12}>
              <CTAContact />
            </Col>
          </Row>
          <Row>
            <Col lg={11}>
              <Footer />
            </Col>
          </Row>
        </Grid >
      </Navbar>
    )
  }
}

export default EsePage;